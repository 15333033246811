<template>
  <div>
    <div class="nav">
      <div class="banner-list" @mouseenter="stopCount" @mouseleave="startCount">
        <div
          class="banner-item"
          v-for="(item, index) in banner"
          :style="{
            transform: `translateX(${(index - currentIndex) * 100}%)`,
            background: `url(${require(`../asset/${
              item.img || 'index/index_25.png'
            }`)}) no-repeat center/cover`,
          }"
          :key="index"
        >
          <div class="text-container" v-if="isHome && index === 0">
            <div class="top-text">{{ item.topText }}</div>
            <div class="title">{{ item.title }}</div>
            <div class="line"></div>
            <div class="desc" v-html="item.desc"></div>
          </div>
          <div class="text-container2" v-else>
            <div
              class="top-text"
            >
              {{ item.topText }}
            </div>
            <div class="title">{{ item.title }}</div>
            <div class="line"></div>
            <div
              class="desc"
              v-html="item.desc"
              :style="{ fontSize: item.descFont ?? '24px' }"
            ></div>
            <div class="button" @click="handleButton(item.buttonText)">
              {{ item.buttonText }}
            </div>
          </div>
        </div>
      </div>
      <div class="content">
        <div class="content-limit">
          <img class="logo" src="../asset/index/index_26.png" alt="" />
          <div class="slogan">国家高新技术企业<br />国家3A资质认证</div>
          <div class="tabs">
            <div class="query">
              <div
                class="button"
                @mousedown.stop="switchNav"
                :style="{
                  background: `url(${navicon2}) no-repeat center/30px`,
                }"
              >
                <!-- <img class="button-icon" :src="navicon2" alt="" /> -->
              </div>
              <div class="list" v-if="showNav">
                <div
                  class="item"
                  v-for="(item, index) of globalData.common.navs"
                  :key="index"
                  :class="{ active: bannerIndex == index }"
                >
                  <div ref="tabs" class="inner" @click="nav(index, item.name)">
                    {{ item.name }}
                  </div>
                  <div class="child" v-if="typeof item.route !== 'string'">
                    <div class="child-inner">
                      <div
                        class="child-item"
                        :class="{
                          'child-active': bannerChildIndex == innerIndex,
                        }"
                        @mousedown="childNav(index, innerIndex)"
                        v-for="(innerItem, innerIndex) of item.route"
                        :key="innerIndex"
                      >
                        <img
                          class="nav-icon"
                          :src="
                            require(`../asset/nav/${
                              innerItem.icon || 'software-dev-icon3'
                            }.png`)
                          "
                          alt=""
                        />
                        {{ innerItem.name }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="phone-content">
              <div class="phone" @click="call('400-849-4999')">
                <img
                  class="phone-icon"
                  src="../asset/index/index_63.png"
                  alt=""
                />
                全国服务热线:
                <div class="phone-num">400-849-4999</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="hover content-limit">
        <!-- <div class="txt">
          <slot name="hover"></slot>
        </div> -->
        <div class="digital">
          <slot name="digital"></slot>
        </div>
      </div>
      <div class="indicator" v-if="isHome">
        <div
          class="indicator-item"
          :class="{ 'indicator-item-active': currentIndex === index }"
          v-for="(item, index) in banner"
          :key="index"
          @click="setCurrentIndex(index)"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters } from "vuex";
import storage from "../utils/utils.js";
import navicon2 from "../asset/index/navicon2.png";
export default {
  data() {
    return {
      showNav: true,
      onPhone: false,
      navicon2,
      currentIndex: 0,
      timer: null,
    };
  },
  props: {
    banner: {
      type: Array,
      required: true,
    },
    // 是否首页，轮播样式不一样
    isHome: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    // ...mapMutations({
    //   setBannerIndex: "setBannerIndex",
    //   setBannerChildIndex: "setBannerChildIndex",
    // }),
    call(number) {
      window.location.href = `tel:${number}`;
    },
    getSize() {
      let width = document.documentElement.clientWidth;
      if (width <= 767) {
        this.showNav = false;
        this.onPhone = true;
      } else {
        this.showNav = true;
        this.onPhone = false;
      }
    },
    switchNav() {
      this.showNav = !this.showNav;
    },
    hideNav(e) {
      if (this.onPhone && !this.$refs?.tabs?.includes?.(e.target)) {
        this.showNav = false;
      }
    },
    nav(index, name) {
      if (name === '项目案例') {
        this.$router.push('/project-case');
        return;
      }
      let url = this.globalData.common.navs[index].route;
      if (typeof url === "string") {
        this.$router.push(url);
      } else {
        this.$router.push(url[0].path);
      }
    },
    childNav(index, innerIndex) {
      let url = this.globalData.common.navs[index].route[innerIndex].path;
      this.$router.push(url);
    },
    startCount() {
      this.timer = setInterval(() => {
        if (this.currentIndex < this.banner?.length - 1) {
          this.currentIndex++;
        } else {
          this.currentIndex = 0;
        }
      }, 5000);
    },
    stopCount() {
      clearInterval(this.timer);
    },
    setCurrentIndex(index) {
      this.currentIndex = index;
    },
    handleButton(name) {
      if (name === "立即咨询") {
        window.open(
          this.$store.getters.globalData.common["53kf_src"],
          "_blank",
          "height=600,width=800,top=50,left=200,status=yes,toolbar=no,menubar=no,resizable=no,scrollbars=no,location=no,titlebar=no"
        );
      } else this.$bus.$emit("openQuotation");
    },
  },
  computed: {
    // ...mapState({
    //   bannerIndex: "bannerIndex",
    //   bannerChildIndex: "bannerChildIndex",
    // }),
    ...mapGetters(["globalData"]),
    bannerIndex() {
      return this.globalData.common.navs.findIndex((which) => {
        if (typeof which.route === "string") {
          return which.route === this.$route.path;
        } else {
          return (
            which.route.findIndex((item) => item.path.includes(this.$route.path)) > -1
          );
        }
      });
    },
    bannerChildIndex() {
      if (this.bannerIndex === -1) return -1;
      const navs = this.globalData.common.navs || [];
      const nav = navs[this.bannerIndex];
      if (Array.isArray(nav.route)) {
        return nav.route.findIndex((item) => item.path === this.$route.path);
      }
      return -1;
    },
  },
  mounted() {
    window.addEventListener("resize", this.getSize);
    this.getSize();
    window.addEventListener("mousedown", this.hideNav);
    this.timer = setInterval(() => {
      if (this.currentIndex < this.banner?.length - 1) {
        this.currentIndex++;
      } else {
        this.currentIndex = 0;
      }
    }, 5000);
  },
  destroyed() {
    clearInterval(this.timer);
    window.removeEventListener("resize", this.getSize);
    window.removeEventListener("mousedown", this.hideNav);
  },
};
</script>

<style scoped lang="scss">
.nav {
  font-size: 0;
  position: relative;
  width: 100%;
  top: 0;
  padding-top: 37.835%;
}

.banner {
  width: 100%;
}

.content {
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  background: white;
  position: fixed;
  z-index: 999;
  height: 100px;
  @media (max-width: 767px) {
    height: 60px;
  }
}
.content-limit {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.logo {
  width: 138px;
  pointer-events: none;
  @media (max-width: 767px) {
    width: 80px;
  }
}
.slogan {
  font-size: 14px;
  color: #666666;
  margin-left: 40px;
  flex-shrink: 0;
  line-height: 2;
}

.tabs {
  display: flex;
  align-items: stretch;
  justify-content: flex-end;
  @media (max-width: 767px) {
    flex-direction: row-reverse;
  }
}
.list {
  color: white;
  font-size: 18px;
  display: flex;
  align-items: stretch;
  height: 100px;

  @media (max-width: 767px) {
    display: block;
    position: absolute;
    right: 0;
  }
}
.query {
  margin-left: 120px;
  @media (max-width: 767px) {
    margin-left: 20px;
  }
  .button {
    width: 133px;
    height: 100px;
    display: none;
    cursor: pointer;

    // .button-icon {
    //   width: 70px;
    //   @media (max-width: 767px) {
    //     width: 40px;
    //   }
    // }
    @media (max-width: 767px) {
      width: 60px;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
.phone-content {
  display: flex;
  @media (max-width: 767px) {
    flex-wrap: wrap;
  }
}
.phone {
  font-size: 24px;
  color: #3295f0;
  display: flex;
  align-items: center;
  margin-left: 60px;
  white-space: nowrap;
  @media (max-width: 767px) {
    margin-left: 10px;
    font-size: 10px;
  }
  .phone-icon {
    width: 26px;
    margin-right: 8px;
    @media (max-width: 767px) {
      width: 12px;
    }
  }
}

.item {
  padding: 0 34px;
  height: 100px;
  line-height: 1;
  position: relative;
  cursor: pointer;
  font-size: 18px;
  color: #333333;
  font-weight: bold;
  white-space: nowrap;

  .inner {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
  }
  @media (max-width: 767px) {
    width: auto;
    padding: 0 20px;
    background: linear-gradient(180deg, #3197f1 0%, #3384e8 100%);
    height: 60px;
    font-size: 14px;
  }
  &:hover {
    .child {
      display: block;
    }
  }
  .child {
    position: absolute;
    top: 100%;
    padding-top: 16px;
    left: -14px;
    right: -14px;
    display: none;
    .child-inner {
      background: white;
      // background: linear-gradient(180deg, #3197f1 0%, #3384e8 100%);
      padding: 4px;
      border-radius: 4px;
    }
    @media screen and (max-width: 767px) {
      left: auto;
      right: 100%;
      top: 0;
      padding-top: 0;
      padding-right: 30px;
      height: 60px;
    }
    .child-item {
      padding: 0 12px;
      height: 30px;
      font-size: 16px;
      color: #000;
      display: flex;
      align-items: center;
      justify-content: start;
      font-weight: normal;
      margin-bottom: 4px;
      border-radius: 4px;
      .nav-icon {
        width: 18px;
        margin-right: 4px;
        position: relative;
        top: 1px;
      }
      &:hover {
        color: #3384e8;
      }
      &:last-child {
        margin-bottom: 0;
      }
      @media screen and (max-width: 767px) {
        font-size: 14px;
        height: 60px;
      }
    }
    .child-active {
      // color: #3384e8;
    }
  }
  &::after {
    content: "";
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 4px;
    background-color: #3384e8;
    transition: width 0.3s ease;
  }
}

.item:hover,
.active {
  color: #3384e8;
  &::after {
    width: 50%;
  }
}

.hover {
  position: absolute;
  left: 50%;
  // top: 100px;
  // height: calc(100% - 100px);
  transform: translateX(-50%);
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: -30px;
  .txt {
    line-height: 1;
    text-align: center;
    padding: 0 20px;
  }
  .digital {
    position: absolute;
    left: 0;
    top: 100%;
    width: 100%;
    transform: translateY(-50%);
    @media screen and (max-width: 767px) {
      left: 50%;
      right: auto;
      transform: translate(-50%, -50%);
    }
  }
  @media screen and (max-width: 767px) {
    justify-content: center;
  }
}
.banner-list {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  padding-top: 100px;
  box-sizing: border-box;
  .banner-item {
    width: 100%;
    height: calc(100% - 100px);
    position: absolute;
    transition: 0.5s all;
    text-align: left;
    .text-container {
      position: absolute;
      // width: 100%;
      // height: 100%;
      text-align: center;
      color: #000;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      .top-text {
        font-size: 32px;
        font-family: Microsoft YaHei;
      }
      .title {
        background: linear-gradient(to right, #2f74ff 45%, #1eb9e9 57%);
        font-size: 70px;
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
        font-weight: bold;
        margin: 40px 0 25px 0;
        // min-width: 62.5rem;
      }
      .line {
        height: 2px;
        margin: 0 22%;
        background: linear-gradient(
          90deg,
          rgba(0, 130, 255, 0) 0%,
          rgba(0, 130, 255, 0.99) 50%,
          rgba(255, 255, 255, 0) 100%
        );
      }
      .desc {
        margin-top: 25px;
        font-size: 24px;
        font-family: Microsoft YaHei;
      }
    }
    .text-container2 {
      position: absolute;
      // padding-left: 14vw;
      left: 13%;
      top: 22%;
      .top-text {
        font-size: 32px;
        font-family: Microsoft YaHei;
      }
      .title {
        color: #0082ff;
        font-size: 70px;
        font-weight: bold;
        margin: 40px 0 25px 0;
        // min-width: 62.5rem;
      }
      .line {
        height: 2px;
        // width: 80%;
        background: linear-gradient(
          to right,
          rgba(0, 130, 255, 0.99) 0%,
          #1eb9e9,
          40%,
          rgba(255, 255, 255, 0) 100%
        );
      }
      .desc {
        margin-top: 25px;
        font-size: 1.25rem;
        font-family: Microsoft YaHei;
      }
      .button {
        margin-top: 1.5625rem;
        width: 8.5938rem;
        height: 3.125rem;
        text-align: center;
        line-height: 3.125rem;
        color: #0082ff;
        font-size: 1.25rem;
        border: 1px solid #6eb7ff;
        border-radius: 5px;
        cursor: pointer;
        &:hover {
          color: #fff;
          background: #0082ff;
        }
      }
    }
    .more-button {
      position: absolute;
      left: 200px;
      bottom: 200px;
      color: #0082ff;
      font-size: 24px;
      width: 165px;
      height: 60px;
      text-align: center;
      line-height: 60px;
      border: 1px solid #6eb7ff;
      border-radius: 5px;
      cursor: pointer;
    }
    .banner-limit {
      max-width: 1920px;
      margin: 0 auto;
      padding: 0 8.5%;
      box-sizing: border-box;
      position: relative;
      top: 25%;
      .banner-buttons {
        display: flex;
        align-items: center;
        gap: 40px;
      }
      .banner-button {
        font-size: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 230px;
        height: 64px;
        border-radius: 40px;
        margin-top: 30px;
        cursor: pointer;
        font-weight: bold;
        box-sizing: border-box;
      }
      .banner-button1 {
        background-color: transparent;
        color: white;
        border: 1px solid white;
      }
      .banner-button1-c {
        border-color: #096dd9;
        color: #096dd9;
      }
      .banner-button2 {
        color: rgba(9, 109, 217, 1);
        background-color: white;
      }
      .banner-button2-c {
        color: white;
        background-color: #096dd9;
      }
    }
  }
}
.indicator {
  position: absolute;
  bottom: 85px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  gap: 8px;
  .indicator-item {
    width: 35px;
    height: 7px;
    background: #afb5be;
    cursor: pointer;
    &:hover {
      background: #3192ee;
    }
  }
  .indicator-item-active {
    background: #3192ee;
  }
}
@media screen and (max-width: 1792px) {
  .text-container,.text-container2 {
    .top-text {
      font-size: 1.25rem !important;
    }
    .line {
      height: 1px !important;
    }
    .title {
      font-size: 2.0833rem !important;
    }
    .desc {
      font-size: .9375rem !important;
    }
    .button {
      width: 6.5104rem !important;
      height: 2.0833rem !important;
      line-height: 2.0833rem !important;
      font-size: .9375rem !important;
    }
  }
}
</style>
