<template>
	<div class="development-type">
		<div class="main">
			<div class="dt-title">{{ type.title }}</div>
			<div class="dt-subtitle">{{ type.subTitle }}</div>

			<div class="dt-list">
				<div class="dt-li" v-for="(item, i) in type.list" :key="i">
					<div class="li-card">
						<div class="card-img-content">
							<img :src="getImg(item.icon)" alt="">
						</div>
						<div class="card-description" :title="item.description">
							<div>{{ item.description }}</div>
						</div>
					</div>
					<div class="li-title">{{ item.title }}</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "DevelopmentType",
	props: ['type'],
	methods: {
		getImg (file) {
			return `${ window.origin }${ process.env.BASE_URL || '/' }${ file }`
		},
	},
}
</script>

<style scoped lang="scss">
$baseSize: 24;
@function px2rem($px) {
	@if calc($px / $baseSize * 0.75) <= 0.5 {
		@return 14px;
	}
	@return calc($px / $baseSize * 0.75rem);
}
.development-type {
	padding: 100px 0 50px 0;
	background: #f5f5f5;
	box-sizing: border-box;
	.main {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
	.dt-title {
		font-size: px2rem(48);
		font-weight: bold;
		color: #333333;
		&::after {
			display: block;
			content: '';
			width: 100%;
			height: 15px;
			background: #0F52FB;
			margin-top: 6px;
		}
	}
	.dt-subtitle {
		font-size: px2rem(18);
		font-weight: 400;
		color: #999999;
		display: flex;
		justify-content: flex-start;
		margin-top: px2rem(26);
	}
	.dt-list {
		width: 100%;
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		gap: px2rem(80);
		justify-content: space-between;
		margin-top: px2rem(50);
		box-sizing: border-box;
		.dt-li {
			display: flex;
			flex-direction: column;
		}
		.li-card {
			aspect-ratio: 1;
			background: white;
			display: flex;
			flex-direction: column;
			box-sizing: border-box;
			border-radius: 8px;
			box-shadow: 5px 5px 70px 1px #E8E8E8;
			.card-img-content {
				aspect-ratio: 1;
				max-width: 238px;
				flex: 1;
				display: flex;
				justify-content: center;
				align-items: center;
				margin: 0 auto;
				img{
					width: 76px;
					height: 83px;
					object-fit: contain;
				}
			}
			.card-description {
				border-radius: 8px 8px 8px 8px;
				background: #000000;
				flex-shrink: 0;
				font-size: px2rem(14);
				font-weight: 400;
				color: #FFFFFF;
				line-height: 2;
				padding: px2rem(24);
				box-sizing: border-box;
				display: flex;
				justify-content: center;
				align-items: center;
				height: px2rem(128);

				div {
					min-height: 28px;/* no */
					height: 100%;
				}
			}
		}
		.li-title {
			flex-shrink: 0;
			font-size: px2rem(24);
			font-weight: bold;
			color: #333333;
			text-align: center;
			margin-top: 24px;
		}
	}
}
@media screen and (max-width: 1100px) {
	.development-type {
		.dt-list {
			grid-template-columns: repeat(4, 1fr);
			column-gap: 10px;
			.li-card {
				min-width: 200px;
			}
		}
	}
}
@media screen and (max-width: 875px) {
	.development-type {
		.dt-list {
			grid-template-columns: repeat(3, 1fr);
			column-gap: 10px;
			.li-card {
				min-width: 150px;
				.card-img-content {

				}
				.card-description {
					padding: 10px;
					height: 76px;
				}
			}
		}
	}
}
@media screen and (max-width: 600px) {
	.development-type {
		padding: 50px 0 25px 0;
		.dt-title {
			font-size: 24px;
		}
		.dt-list {
			grid-template-columns: repeat(2, 1fr);
			column-gap: 10px;
			row-gap: 20px;
			.li-card {
				min-width: 120px;
				.card-img-content {

				}
				.card-description {
					font-size: 12px;
					padding: 10px;
					height: 64px;
				}
			}
			.li-title {
				font-size: 20px;
			}
		}
	}
}
</style>
