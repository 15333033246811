import Vue from 'vue';
import VueRouter from 'vue-router';
import index from '../views/index.vue';
import development from '../views/development.vue';
import DevelopmentMobile from '../views/development-mobile.vue';
import about from '../views/about.vue';
import serve from '../views/serve.vue';
import DevelopmentApplet from '../views/development-applet.vue';
import Solution from "../views/solution.vue";
import projectCase from "../views/project-case.vue";

Vue.use(VueRouter);


const routes = [{
	path: '/',
	component: index,
}, {
	path: '/development',
	component: development,
}, {
	path: '/development-mobile',
	component: DevelopmentMobile,
}, {
	path: '/solution',
	component: Solution
}, {
	path: '/project-case',
	component: projectCase
}, {
	path: '/about',
	component: about,
}, {
	path: '/serve',
	component: serve,
}, {
	path: '/development-applet',
	component: DevelopmentApplet,
}, {
    path: '*',
    redirect: '/'
}]

const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push (location) {
	return originalPush.call(this, location).catch(err => err)
}

let router = new VueRouter({
	routes,
});

router.beforeEach((to, from, next) => {
	next()
})

router.afterEach(() => {
	window.scrollTo({
		top: 0,
		left: 0,
		behavior: "smooth"
	})
})

export default router
