<template>
  <div>
    

    <div class="list">
      <template v-for="(item, index) of globalData.index.process.list">
        <div class="item">
          <div class="top">
            <img class="top-icon" :src="process[index]" alt="" />
          </div>
          <div class="process-strip">
            <img class="strip-icon" :src="stripDot" alt="" />
          </div>
          <div class="title">{{ item.name }}</div>
          <div class="detail">
            <div
              class="detail-item"
              v-for="(innerItem, innerIndex) of item.detail"
              :key="innerIndex"
            >
              {{ innerItem }}
            </div>
          </div>
        </div>
        <div
          class="arrow"
          v-if="index !== globalData.index.process.list.length - 1"
        >
          <div class="arrow-box">
            <img class="arrow-icon" :src="stripArrow" alt="" />
          </div>
          <div class="arrow-strip"></div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import process1 from "../asset/index/index_03.png";
import process2 from "../asset/index/index_04.png";
import process3 from "../asset/index/index_05.png";
import process4 from "../asset/index/index_06.png";
import process5 from "../asset/index/index_07.png";
import process6 from "../asset/index/index_08.png";
import process7 from "../asset/index/index_09.png";
import stripDot from "../asset/index/index_7.png";
import stripArrow from "../asset/index/index_27.png";

export default {
  computed: {
    ...mapGetters(["globalData"]),
  },
  data() {
    return {
      process: [
        process1,
        process2,
        process3,
        process4,
        process5,
        process6,
        process7,
      ],
      stripArrow,
      stripDot,
    };
  },
};
</script>

<style scoped lang="scss">
.list {
  text-align: center;
  display: flex;
  align-items: flex-start;
  margin-top: 40px;
  justify-content: center;
  @media screen and (max-width: 767px) {
    justify-content: center;
  }

  .process-container {
    text-align: center;
    display: flex;
    align-items: flex-start;
    // @media screen and (min-width: 1920px) {
    //   flex: 1;
    // }
  }
  .item {
    margin-bottom: 75px;
    .top {
      width: 150px;
      height: 90px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: linear-gradient(180deg, #3197f1 0%, #3384e8 100%);
      margin-bottom: 30px;
      .top-icon {
        width: 50px;
      }
    }
    .process-strip {
      background: #cde5fb;
      height: 32px;
      border-radius: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 44px;
      .strip-icon {
        width: 40px;
      }
    }
    .title {
      color: #3384e8;
      font-size: 24px;
      font-weight: bold;
      line-height: 1;
      margin-bottom: 24px;
    }
    .detail {
      line-height: 1;
      .detail-item {
        color: #666666;
        font-size: 16px;
        margin-bottom: 8px;
      }
    }
  }
  .arrow {
    width: 100px;
    @media screen and (min-width: 1920px) {
      flex: 1;
    }
    .arrow-box {
      height: 90px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 30px;
      padding: 0 16px;

      .arrow-icon {
        width: 69px;
      }
    }
    .arrow-strip {
      background: #cde5fb;
      height: 32px;
      transform: scale(1.3, 1);
    }
  }
}
</style>
