<template>
  <div class="solution">
    <top-nav :banner="globalData.solution.imgList">
    </top-nav>
    <common-solution :type="globalData.solution.type"></common-solution>
    <div class="manifest">
      <strip-title
        :title="globalData.solution.manifest.title"
        :brief="globalData.solution.manifest.brief"
      ></strip-title>
      <div class="list content-limit">
        <div
          class="item"
          v-for="(item, index) of globalData.solution.manifest.list"
          :key="index"
        >
          <img class="icon" :src="sol[index]" alt="" />
          <div class="name">{{ item }}</div>
        </div>
      </div>
    </div>
    <div class="ability">
      <strip-title :title="globalData.solution.ability.title"></strip-title>
      <div class="list content-limit">
        <div
          class="item"
          v-for="(item, index) of globalData.solution.ability.list"
          :key="index"
        >
          <div class="content">
            <div class="name">
              <div class="icon" v-html="ability[index]"></div>
              <div class="txt">{{ item.title }}</div>
            </div>
            <pre class="brief">{{ item.subtitle }}</pre>
          </div>
          <div class="hide">
            <div class="hideicon" v-html="ability[index]"></div>
            {{ item.title }}
          </div>
        </div>
      </div>
    </div>
    <div class="serv">
      <strip-title :title="globalData.solution.serv.title"></strip-title>
      <div class="list content-limit">
        <div
          class="item"
          v-for="(item, index) of globalData.solution.serv.list"
          :key="index"
        >
          <img class="icon" :src="serv[index]" alt="" />
          <div class="title">{{ item.title }}</div>
          <div class="brief">{{ item.brief }}</div>
        </div>
      </div>
    </div>
    <value :value="globalData.solution.value"></value>
    <my-footer></my-footer>
  </div>
</template>

<script>
import { WOW } from "wowjs";
import { mapGetters } from "vuex";
import CommonSolution from "./solution/common-solution.vue";
import Ability from "./solution/ability.vue";
import Value from "./solution/value.vue";
import banner from "../asset/solution/n_sol_35.png";

import solic1 from "../asset/solution/n_sol_30.png";
import solic2 from "../asset/solution/n_sol_26.png";
import solic3 from "../asset/solution/n_sol_4.png";
import solic4 from "../asset/solution/n_sol_10.png";
import solic5 from "../asset/solution/n_sol_17.png";
import solic6 from "../asset/solution/n_sol_17.png";
import solic7 from "../asset/solution/n_sol_27.png";
import solic8 from "../asset/solution/n_sol_5.png";
import solic9 from "../asset/solution/n_sol_11.png";
import solic10 from "../asset/solution/n_sol_8.png";

import solic11 from "../asset/solution/n_sol_12.png";
import solic12 from "../asset/solution/n_sol_28.png";
import solic13 from "../asset/solution/n_sol_6.png";
import solic14 from "../asset/solution/n_sol_12.png";
import solic15 from "../asset/solution/n_sol_13.png";
import solic16 from "../asset/solution/n_sol_23.png";
import solic17 from "../asset/solution/n_sol_29.png";
import solic18 from "../asset/solution/n_sol_7.png";
import solic19 from "../asset/solution/n_sol_14.png";
import solic20 from "../asset/solution/n_sol_4.png";

import solic21 from "../asset/solution/n_sol_24.png";
import solic22 from "../asset/solution/n_sol_2.png";
import solic23 from "../asset/solution/n_sol_8.png";
import solic24 from "../asset/solution/n_sol_15.png";
import solic25 from "../asset/solution/n_sol_21.png";
import solic26 from "../asset/solution/n_sol_25.png";
import solic27 from "../asset/solution/n_sol_3.png";
import solic28 from "../asset/solution/n_sol_9.png";
import solic29 from "../asset/solution/n_sol_16.png";
import solic30 from "../asset/solution/n_sol_22.png";

import servic1 from "../asset/solution/n_sol_41.png";
import servic2 from "../asset/solution/n_sol_39.png";
import servic3 from "../asset/solution/n_sol_40.png";

export default {
  name: "solution",
  components: {
    CommonSolution,
    Ability,
    Value,
  },
  data: function () {
    return {
      banner,
      sol: [
        solic1,
        solic2,
        solic3,
        solic4,
        solic5,
        solic6,
        solic7,
        solic8,
        solic9,
        solic10,
        solic11,
        solic12,
        solic13,
        solic14,
        solic15,
        solic16,
        solic17,
        solic18,
        solic19,
        solic20,
        solic21,
        solic22,
        solic23,
        solic24,
        solic25,
        solic26,
        solic27,
        solic28,
        solic29,
        solic30,
      ],
      ability: [
        "<svg xmlns='http://www.w3.org/2000/svg' fill='currentColor' width='24' height='24' viewBox='0 0 24 24'><g transform='translate(-12.193 -0.258)'><path class='a' d='M477.852,516.6a.829.829,0,0,1-.566-1.436l3.6-3.356a.829.829,0,0,1,1.132,1.213l-3.6,3.356A.826.826,0,0,1,477.852,516.6Z' transform='translate(-453.64 -499.37)'/><path class='a' d='M655.906,516.6a.826.826,0,0,1-.565-.223l-3.6-3.356a.829.829,0,1,1,1.132-1.213l3.6,3.356a.829.829,0,0,1-.566,1.436Z' transform='translate(-623.857 -499.371)'/><path class='a' d='M639.171,21.217a.823.823,0,0,1-.829-.817V13.049a.829.829,0,0,1,1.659,0V20.4A.823.823,0,0,1,639.171,21.217Zm4.735-14.427a3.3,3.3,0,0,1-3.317-3.266,3.317,3.317,0,0,1,6.634,0A3.3,3.3,0,0,1,643.906,6.791Zm0-4.9a1.633,1.633,0,1,0,1.659,1.633,1.648,1.648,0,0,0-1.659-1.633Z' transform='translate(-611.03 0)'/><path class='a' d='M631.55,106.527h-2.488a.829.829,0,0,1,0-1.659h2.488a.829.829,0,0,1,0,1.659Z' transform='translate(-601.22 -102.123)'/><path class='a' d='M177.134,147.827a.826.826,0,0,1-.509-.175,4.423,4.423,0,0,1,4.849-7.365.829.829,0,0,1-.8,1.453,2.765,2.765,0,0,0-3.029,4.6.829.829,0,0,1-.51,1.483Zm14.371-1.905a.829.829,0,0,1-.829-.829v-2.865a.829.829,0,1,1,1.659,0v2.865a.829.829,0,0,1-.829.829Z' transform='translate(-158.63 -136.164)'/><path class='a' d='M34.8,24.259H13.588a1.381,1.381,0,0,1-1.4-1.364V1.623a1.381,1.381,0,0,1,1.4-1.364H26.425a1.381,1.381,0,0,1,1.4,1.364V8.986H34.8a1.381,1.381,0,0,1,1.4,1.364V22.9a1.381,1.381,0,0,1-1.4,1.364Zm-20.93-1.636H34.518v-12H26.983a.828.828,0,0,1-.837-.818V1.9H13.867V22.623Zm20.93-12h0Z' transform='translate(0 -0.001)'/></g></svg>",
        "<svg xmlns='http://www.w3.org/2000/svg' fill='currentColor' width='24.064' height='24' viewBox='0 0 24.064 24'><g transform='translate(-79.6 -84.4)'><path class='a' d='M98.266,100.7a.88.88,0,1,1,0,1.756H85a.88.88,0,1,1,0-1.756Zm2-17.3a3.154,3.154,0,0,1,3.4,2.833v18.331a3.157,3.157,0,0,1-3.4,2.836H83a3.156,3.156,0,0,1-3.4-2.833V86.233A3.153,3.153,0,0,1,83,83.4Zm1.7,21.161V86.23c0-.783-.764-1.13-1.7-1.13H83c-.937,0-1.7.347-1.7,1.13v18.331c0,.783.764,1.13,1.7,1.13h17.273C101.206,105.691,101.967,105.344,101.967,104.561Z' transform='translate(0 1)'/><path class='a' d='M607.422,522.2a1.122,1.122,0,1,0,1.122,1.122A1.123,1.123,0,0,0,607.422,522.2Z' transform='translate(-511.969 -426.22)'/><path class='a' d='M314.291,210.2a.86.86,0,0,0-.615-.246H311.1a1.126,1.126,0,0,0-.892-.453,1.113,1.113,0,0,0-1.108.917,1.156,1.156,0,0,0,.246.945,1.124,1.124,0,0,0,.87.38,1.082,1.082,0,0,0,.92-.5h2.2a.389.389,0,0,1,.26.109l2.2,2.2a.392.392,0,0,0,.271.12h1.306a1.132,1.132,0,0,0,.884.459h.008a1.125,1.125,0,0,0,1.108-.931,1.18,1.18,0,0,0-.246-.957,1.131,1.131,0,0,0-.87-.389,1.069,1.069,0,0,0-.92.489h-.7a.357.357,0,0,1-.257-.1l-1.832-1.8Zm5.348,5.037a1.057,1.057,0,0,0-1.007.627h-3.325a.86.86,0,0,0-.615.243l-2.542,2.534a.361.361,0,0,1-.26.109h-1.443a.93.93,0,0,0-.526-.422,1.046,1.046,0,0,0-.394-.081,1.162,1.162,0,0,0-1.068.71,1.128,1.128,0,0,0,.022.853,1.151,1.151,0,0,0,.615.61,1.258,1.258,0,0,0,.4.07,1.047,1.047,0,0,0,.906-.459h1.846a.867.867,0,0,0,.618-.257l2.489-2.534a.439.439,0,0,1,.294-.143H318.8l.042.014a1.108,1.108,0,1,0,.8-1.874Zm-7.635-1.3h-1.563a1.028,1.028,0,0,0-.9-.467,1.092,1.092,0,0,0-1.122,1.119,1.107,1.107,0,0,0,1.136,1.127,1.069,1.069,0,0,0,.912-.489h1.521a1.075,1.075,0,0,0,.912.5h.008a1.123,1.123,0,1,0-.9-1.793Zm0,0' transform='translate(-222.384 -122.266)'/></g></svg>",
        "<svg xmlns='http://www.w3.org/2000/svg' fill='currentColor' width='22.086' height='24' viewBox='0 0 22.086 24'><path class='a' d='M126.43,78.805a2.891,2.891,0,0,0-2.487.686l-.925-.515a1.177,1.177,0,0,1-.578-.972V76.118a1.1,1.1,0,0,1,.578-.972l.925-.515a2.917,2.917,0,0,0,2.487.743,2.856,2.856,0,0,0-.694-5.661,2.939,2.939,0,0,0-2.487,1.716,2.6,2.6,0,0,0-.174,1.716l-.868.457a1.061,1.061,0,0,1-1.157,0l-1.735-1.029a1.177,1.177,0,0,1-.578-.972v-.972a2.844,2.844,0,0,0,1.967-3.26,2.908,2.908,0,0,0-2.2-2.23,2.868,2.868,0,0,0-3.586,2.745,2.836,2.836,0,0,0,2.024,2.688v.972a1.105,1.105,0,0,1-.578.972L114.573,73.6a1.061,1.061,0,0,1-1.157,0l-.925-.515a2.439,2.439,0,0,0-.231-1.773,2.744,2.744,0,0,0-2.487-1.716,2.842,2.842,0,0,0-2.892,3.374,2.955,2.955,0,0,0,2.314,2.287,2.753,2.753,0,0,0,2.487-.743l.925.515a1.177,1.177,0,0,1,.578.972V77.89a1.105,1.105,0,0,1-.578.972l-.925.515a2.891,2.891,0,0,0-2.487-.686,2.955,2.955,0,0,0-2.314,2.287,2.9,2.9,0,0,0,2.834,3.488,3,3,0,0,0,2.487-1.43,2.392,2.392,0,0,0,.347-2l.925-.515a1.061,1.061,0,0,1,1.157,0l1.793,1.029a1.177,1.177,0,0,1,.578.972v.972a2.844,2.844,0,0,0-1.967,3.26,2.894,2.894,0,0,0,5.726-.572,2.836,2.836,0,0,0-2.024-2.688v-.972a1.105,1.105,0,0,1,.578-.972l1.793-1.029a1.061,1.061,0,0,1,1.157,0l.925.515a2.534,2.534,0,0,0,.4,2.059,2.923,2.923,0,0,0,2.487,1.43,2.863,2.863,0,0,0,2.776-3.431,3.175,3.175,0,0,0-2.429-2.287Zm-.521-7.491a1.144,1.144,0,1,1-1.157,1.144A1.154,1.154,0,0,1,125.91,71.314Zm-17.352,1.144a1.157,1.157,0,1,1,1.157,1.144A1.154,1.154,0,0,1,108.558,72.458Zm1.157,10.293a1.144,1.144,0,1,1,1.157-1.144A1.154,1.154,0,0,1,109.715,82.751Zm8.1-16.012a1.144,1.144,0,1,1-1.157,1.144A1.154,1.154,0,0,1,117.812,66.739Zm0,20.587a1.144,1.144,0,1,1,1.157-1.144A1.154,1.154,0,0,1,117.812,87.326Zm2.892-8.635-2.892,1.658-2.892-1.658V75.374l2.892-1.658,2.892,1.658Zm5.205,4.06a1.144,1.144,0,1,1,1.157-1.144A1.154,1.154,0,0,1,125.91,82.751Z' transform='translate(-106.827 -65.052)'/></svg>",
        "<svg xmlns='http://www.w3.org/2000/svg' fill='currentColor' width='24' height='24' viewBox='0 0 24 24'><g transform='translate(-112.5 -112.5)'><path class='a' d='M136.019,118.943a.481.481,0,0,0,.481-.481v-5.482a.481.481,0,0,0-.481-.481h-5.482a.481.481,0,0,0-.481.481V115H118.943v-2.02a.481.481,0,0,0-.481-.481h-5.482a.481.481,0,0,0-.481.481v5.482a.481.481,0,0,0,.481.481H115v11.114h-2.02a.481.481,0,0,0-.481.481v5.482a.481.481,0,0,0,.481.481h5.482a.481.481,0,0,0,.481-.481V134h11.114v2.02a.481.481,0,0,0,.481.481h5.482a.481.481,0,0,0,.481-.481v-5.482a.481.481,0,0,0-.481-.481H134V118.943ZM113.942,117.5v-3.559H117.5V117.5Zm3.559,17.557h-3.559V131.5H117.5Zm12.556-4.521v2.02H118.943v-2.02a.481.481,0,0,0-.481-.481h-2.02V118.943h2.02a.481.481,0,0,0,.481-.481v-2.02h11.114v2.02a.481.481,0,0,0,.481.481h2.02v11.114h-2.02A.481.481,0,0,0,130.057,130.538Zm5,.961v3.559H131.5V131.5Zm-3.559-14v-3.559h3.559V117.5Z'/><path class='a' d='M370.487,362.863a.122.122,0,0,0-.172,0l-.518.518a.122.122,0,0,0,0,.172l.521.521-3.445,3.445-.987-.987a.122.122,0,0,0-.172,0L363.8,368.45a2.047,2.047,0,0,0-.609,1.419,2.01,2.01,0,0,0,3.431,1.443l1.938-1.938a.122.122,0,0,0,0-.172l-.993-.995,3.445-3.445.521.52a.122.122,0,0,0,.172,0l.518-.518a.122.122,0,0,0,0-.172Zm-4.558,7.76a1.035,1.035,0,1,1-1.464-1.464l1.335-1.335,1.465,1.465Z' transform='translate(-243.218 -242.87)'/></g></svg>",
      ],
      serv: [servic1, servic2, servic3],
    };
  },
  computed: {
    ...mapGetters(["globalData"]),
  },
  mounted() {
    // this.$store.commit('setBannerIndex', 2);
    // this.$nextTick(() => {
    new WOW({
      live: false,
    }).init();
    // })
  },
};
</script>

<style scoped lang="scss">
$baseSize: 24;
@function px2rem($px) {
  @if calc($px / $baseSize * 0.75) <= 0.5 {
    @return 14px;
  }
  @return calc($px / $baseSize * 0.75rem);
}
.solution {
  .banner-title {
    color: white;
    font-size: px2rem(48);
    font-weight: bold;
    margin-bottom: px2rem(64);
    color: rgba(51, 132, 232, 1);
  }
  .banner-subtitle {
    color: white;
    font-size: 24px;
    margin-bottom: px2rem(64);
    text-align: left;
    line-height: 2;
  }
  .banner-txt {
    background: #0f52fb;
    border-radius: 4px 4px 4px 4px;
    padding: px2rem(18) px2rem(27);
    color: white;
    font-size: px2rem(24);
    display: inline-block;
  }
}
@media screen and (max-width: 768px) {
  .solution {
    .banner-title {
      font-size: 24px;
      margin-bottom: px2rem(32);
      text-align: center;
    }
    .banner-subtitle {
      width: 100%;
      font-size: 18px;
    }
  }
}

.manifest {
  padding: 80px 0;
  background: rgba(221, 236, 250, 0.5);
  .list {
    display: flex;
    flex-wrap: wrap;
    margin-top: 60px;
    .item {
      background: white;
      padding: 30px 0;
      box-shadow: 3px 5px 8px 1px rgba(170, 170, 170, 0.16);
      width: calc((100% - 96px) / 5);
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 24px;
      margin-bottom: 24px;
      &:nth-child(5n) {
        margin-right: 0;
      }
    }
  }
}
.ability {
  padding: 80px 0;
  .list {
    display: flex;
    margin-top: 80px;
    .item {
      background: white;
      padding: 30px 0;
      box-shadow: 3px 5px 8px 1px rgba(170, 170, 170, 0.16);
      width: calc((100% - 210px) / 4);
      height: 350px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 70px;
      position: relative;
      &:nth-child(4n) {
        margin-right: 0;
      }
      &:nth-child(1) {
        background: url(../asset/solution/n_sol_42.png) no-repeat center/cover;
      }
      &:nth-child(2) {
        background: url(../asset/solution/n_sol_33.png) no-repeat center/cover;
      }
      &:nth-child(3) {
        background: url(../asset/solution/n_sol_31.png) no-repeat center/cover;
      }
      &:nth-child(4) {
        background: url(../asset/solution/n_sol_32.png) no-repeat center/cover;
      }
      &:hover {
        .content {
          top: 100%;
          opacity: 0;
        }
        .hide {
          margin-top: -84px;
          opacity: 1;
        }
      }
      .content {
        color: white;
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        transition: all 0.5s;
        backdrop-filter: blur(10px);
        background: rgba($color: white, $alpha: 0.3);
        text-align: center;

        .name {
          font-size: 24px;
          display: flex;
          align-items: center;
          margin-top: 128px;
          justify-content: center;
          .icon {
            width: 22px;
            margin-right: 16px;
          }
          .txt {
          }
        }
        .brief {
          font-size: 14px;
          margin-top: 36px;
          line-height: 1.5;
        }
      }
      .hide {
        opacity: 0;
        height: 84px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 24px;
        color: rgba(51, 132, 232, 1);
        position: absolute;
        left: 0;
        top: 100%;
        width: 100%;
        background: white;
        transition: all 0.5s;
        line-height: 1;
        .hideicon {
          width: 24px;
          margin-right: 16px;
        }
      }
    }
  }
}

.serv {
  padding: 80px 0;
  background: rgba(221, 236, 250, 0.5);
  .list {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 80px;
    .item {
      padding: 30px 0;
      text-align: center;
      &:nth-child(5n) {
        margin-right: 0;
      }
      .icon {
        width: 180px;
      }
      .title {
        color: rgba(51, 51, 51, 1);
        margin-top: 42px;
        font-size: 24px;
      }
      .brief {
        margin-top: 16px;
        color: rgba(102, 102, 102, 1);
        font-size: 16px;
      }
    }
  }
}
</style>
