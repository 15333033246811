<template>
	<div class="advantage">
		<img class="advantage-bg" src="../../asset/development/advantage_bg.png" alt="">
		<div class="main">
			<div class="ad-title">
				<div class="ad-title-value">{{ advantage.title }}</div>
				<div class="ad-subtitle">{{ advantage.subTitle }}</div>
			</div>
			<div class="ad-list">
				<div class="ad-list-item"
				     v-for="(item, i) in advantage.list"
				     :key="i"
				>
					<div class="li-card">
						<img :src="getImg(item.icon)" alt="">
					</div>
					<div class="li-title">{{ item.title }}</div>
					<div class="li-subtitle">{{ item.subTitle }}</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "Advantage",
	props: ['advantage'],
	methods: {
		getImg (file) {
			return `${ window.origin }${ process.env.BASE_URL || '/' }${ file }`
		},
	},
}
</script>

<style scoped lang="scss">
$baseSize: 24;
@function px2rem($px) {
	@if calc($px / $baseSize * 0.75) <= 0.5 {
		@return 14px;
	}
	@return calc($px / $baseSize * 0.75rem);
}
.advantage {
	padding: px2rem(100) 0;
	background-color: #1A1A1A;
	position: relative;

	.main > * {
		position: relative;
	}
	.main {
		display: grid;
		grid-template-columns: 1fr 2fr;
		position: relative;
		overflow: hidden;
	}
	.advantage-bg {
		opacity: 0;
		transition: all .4s;
		position: absolute;
		left: 0;
		top: 0;
		z-index: 0;
		object-fit: cover;
		width: 100%;
		height: 100%;
	}
	&:hover .advantage-bg {
		opacity: 1;
	}
	.ad-title {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		.ad-title-value {
			font-size: 2rem;
			font-weight: bold;
			color: #FFFFFF;
			&:after {
				content: '';
				display: block;
				width: 100%;
				height: 15px;
				background: #0F52FB;
				margin-top: 6px;
			}
		}
		.ad-subtitle {
			align-self: center;
			font-size: px2rem(18);
			font-weight: 400;
			color: #999999;
			margin-top: px2rem(26);
		}
	}
	.ad-list {
		width: 100%;
		height: 100%;
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		grid-auto-rows: 1fr;
		justify-content: space-around;
		row-gap: px2rem(116);
		column-gap: 20px;
		.ad-list-item {
			width: 100%;
			height: 100%;
			.li-card {
				width: 100%;
				max-width: 200px;
				aspect-ratio: 1;
				background: white;
				display: flex;
				justify-content: center;
				align-items: center;
				overflow: hidden;
				margin: 0 auto;
				img {
					width: 100%;
					height: 100%;
					object-fit: contain;
					transform: translateX(5px);
				}
			}
			.li-title {
				font-size: 1rem;
				font-weight: 500;
				color: #FFFFFF;
				margin-top: px2rem(40);
				text-align: center;
			}
			.li-subtitle {
				font-size: px2rem(14);
				font-weight: 400;
				color: #FFFFFF;
				margin-top: 1rem;
				text-align: center;
				white-space: pre-wrap;
			}
		}
	}
}
@media screen and (max-width: 768px) {
	.advantage .main {
		grid-template-columns: 1fr;
		row-gap: px2rem(100);
		.ad-title .ad-title-value {
			font-size: 24px;
		}
		.ad-list {
			.ad-list-item {
				.li-subtitle {
					font-size: 14px;
				}
			}
		}
	}
}
</style>
