<template>
  <div class="container">
    <top-nav :banner="globalData.developmentMobile.imgList">
    </top-nav>
    <div class="question">
      <strip-title
        :title="globalData.developmentMobile.question.title"
      ></strip-title>
      <div class="content content-limit">
        <div class="left">
          <div class="top box">
            {{ globalData.developmentMobile.question.ques1 }}
          </div>
          <div class="bottom box">
            {{ globalData.developmentMobile.question.ques3 }}
          </div>
        </div>
        <div class="center"></div>
        <div class="right">
          <div class="top box">
            {{ globalData.developmentMobile.question.ques2 }}
          </div>
          <div class="bottom box">
            {{ globalData.developmentMobile.question.ques4 }}
          </div>
        </div>
      </div>
    </div>
    <div class="demand">
      <strip-title
        :title="globalData.developmentMobile.demand.title"
      ></strip-title>
      <div class="list content-limit">
        <div
          class="item"
          v-for="(item, index) of globalData.developmentMobile.demand.list"
          :key="index"
          :style="{
            background: `url(${demand[index]}) no-repeat center/cover`,
          }"
        >
          <div class="content">
            <div class="icon" v-html="item.svg"></div>
            <pre class="title">{{ item.title }}</pre>
            <pre class="brief">{{ item.brief }}</pre>
          </div>
          <div class="name">
            <div class="icon" v-html="item.svg"></div>
            {{ item.title }}
          </div>
        </div>
      </div>
    </div>
    <div class="genius">
      <strip-title
        :title="globalData.developmentMobile.genius.title"
      ></strip-title>
      <div class="list content-limit">
        <div
          class="item"
          v-for="(item, index) of globalData.developmentMobile.genius.list"
          :key="index"
        >
          <img class="icon" :src="genius[index]" alt="" />
          <div class="title">{{ item.title }}</div>
          <pre class="brief">{{ item.brief }}</pre>
        </div>
      </div>
    </div>
    <div class="ours">
      <strip-title
        :title="globalData.developmentMobile.ours.title"
        :brief="globalData.developmentMobile.ours.brief"
      ></strip-title>
      <div class="list content-limit">
        <div
          class="item"
          v-for="(item, index) of globalData.developmentMobile.ours.list"
          :key="index"
        >
          <img class="back" src="../asset/development/n_dev_m_33.png" alt="" />
          <div class="content">
            <img class="icon" :src="ours[index]" alt="" />
            <div class="name">{{ item }}</div>
          </div>
          <img class="extra" src="../asset/development/n_dev_m_34.png" alt="" />
        </div>
      </div>
    </div>
    <div class="system">
      <strip-title
        :title="globalData.developmentMobile.system.title"
        :brief="globalData.developmentMobile.system.brief"
      ></strip-title>
      <div class="list content-limit">
        <div
          class="item"
          v-for="(item, index) of globalData.developmentMobile.system.list"
          :key="index"
        >
          <div class="title">{{ item.title }}</div>
          <pre class="brief">{{ item.brief }}</pre>
        </div>
      </div>
    </div>
    <div class="quality">
      <div class="content content-limit">
        <div class="left">{{ globalData.developmentMobile.quality.title }}</div>
        <div class="right">
          <div
            class="item"
            v-for="(item, index) of globalData.developmentMobile.quality.list"
            :key="index"
          >
            <img class="icon" :src="quality[index]" alt="" />
            <div class="txt">
              <div class="title">{{ item.title }}</div>
              <pre class="brief">{{ item.brief }}</pre>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="process">
      <strip-title
        :title="globalData.index.process.topic.title"
        :brief="globalData.index.process.topic.brief"
      ></strip-title>
      <serve-process class="content-limit"></serve-process>
    </div>
    <div class="inventory">
      <strip-title
        :title="globalData.development.inventory.topic.title"
        :brief="globalData.development.inventory.topic.brief"
      ></strip-title>
      <div class="list content-limit">
        <div
          class="item"
          v-for="(item, index) of globalData.development.inventory.list"
          :key="index"
        >
          <img class="icon" :src="inventory[index]" alt="" />
          <pre class="name">{{ item }}</pre>
        </div>
      </div>
    </div>
    <my-footer></my-footer>
  </div>
</template>
<script>
import { WOW } from "wowjs";
import banner from "../asset/development/n_dev_m_6.png";
import { mapGetters } from "vuex";
import DeliveryChecklist from "./development/DeliveryChecklist.vue";
import Advantage from "./development/Advantage.vue";
import DevelopmentType from "./development/DevelopmentType.vue";
import demandic1 from "../asset/development/n_dev_m_31.png";
import demandic2 from "../asset/development/n_dev_m_3.png";
import demandic3 from "../asset/development/n_dev_m_2.png";
import demandic4 from "../asset/development/n_dev_m_4.png";
import geniusic1 from "../asset/development/n_dev_m_20.png";
import geniusic2 from "../asset/development/n_dev_m_21.png";
import geniusic3 from "../asset/development/n_dev_m_15.png";
import geniusic4 from "../asset/development/n_dev_m_22.png";
import geniusic5 from "../asset/development/n_dev_m_16.png";
import geniusic6 from "../asset/development/n_dev_m_17.png";
import geniusic7 from "../asset/development/n_dev_m_18.png";
import geniusic8 from "../asset/development/n_dev_m_19.png";
import oursic1 from "../asset/development/n_dev_m_35.png";
import oursic2 from "../asset/development/n_dev_m_23.png";
import oursic3 from "../asset/development/n_dev_m_24.png";
import oursic4 from "../asset/development/n_dev_m_14.png";
import quaic1 from "../asset/development/n_dev_m_25.png";
import quaic2 from "../asset/development/n_dev_m_36.png";
import quaic3 from "../asset/development/n_dev_m_37.png";
import quaic4 from "../asset/development/n_dev_m_26.png";
import invic1 from "../asset/development/n_dev_21.png";
import invic2 from "../asset/development/n_dev_33.png";
import invic3 from "../asset/development/n_dev_6.png";
import invic4 from "../asset/development/n_dev_22.png";
import invic5 from "../asset/development/n_dev_7.png";
import invic6 from "../asset/development/n_dev_24.png";
import invic7 from "../asset/development/n_dev_25.png";
import invic8 from "../asset/development/n_dev_26.png";
import invic9 from "../asset/development/n_dev_8.png";
import invic10 from "../asset/development/n_dev_9.png";

export default {
  name: "Development",
  components: {
    DeliveryChecklist,
    Advantage,
    DevelopmentType,
  },
  data: function () {
    return {
      banner,
      demand: [demandic1, demandic2, demandic3, demandic4],
      genius: [
        geniusic1,
        geniusic2,
        geniusic3,
        geniusic4,
        geniusic5,
        geniusic6,
        geniusic7,
        geniusic8,
      ],
      ours: [oursic1, oursic2, oursic3, oursic4],
      quality: [quaic1, quaic2, quaic3, quaic4],
      inventory: [
        invic1,
        invic2,
        invic3,
        invic4,
        invic5,
        invic6,
        invic7,
        invic8,
        invic9,
        invic10,
      ],
    };
  },
  computed: {
    ...mapGetters(["globalData"]),
  },
  mounted() {
    // this.$store.commit('setBannerChildIndex', 1)
    // this.$nextTick(() => {
    new WOW({
      live: false,
    }).init();
    // })
  },
};
</script>
<style scoped lang="scss">
.banner-title {
  color: white;
  font-size: 48px;
  color: #3384e8;
  font-weight: bold;
  margin-bottom: 64px;
  @media screen and (max-width: 767px) {
    text-align: center;
    font-size: 24px;
  }
}
.banner-subtitle {
  color: white;
  font-size: 27px;
  margin-bottom: 64px;
  text-align: left;
  line-height: 2;
  font-family: "Source Han Sans CN-Medium", serif;
  @media screen and (max-width: 767px) {
    text-align: center;
    font-size: 18px;
    white-space: normal;
    word-wrap: break-word;
  }
}
.plan {
  padding: 150px 0 80px;
  background-image: url("../asset/development/dm_bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  @media screen and (max-width: 767px) {
    padding: 150px 20px 80px;
  }
  .analyze {
    .title {
      color: #333333;
      font-size: 36px;
      text-align: center;
      margin-bottom: 64px;
      margin-top: 112px;
    }
    .list {
      margin-bottom: 113px;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      .item {
        background: url(../asset/development/dev_m_02.png) no-repeat
          center/contain;
        color: white;
        // flex: 1;
        width: 20%;
        text-align: center;
        height: 256px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        @media screen and (max-width: 1200px) {
          width: 33%;
          height: 400px;
        }
        @media screen and (max-width: 950px) {
          width: 33%;
          height: 300px;
        }
        @media screen and (max-width: 767px) {
          width: 50%;
          height: 200px;
        }
        .name {
          font-size: 24px;
          width: 100%;
          margin-bottom: 16px;
          @media screen and (max-width: 767px) {
            font-size: 20px;
          }
        }
        .detail {
          font-size: 14px;
          width: 100%;
          font-family: "Source Han Sans CN-Medium", serif;
          @media screen and (max-width: 767px) {
            font-size: 12px;
            white-space: normal;
            word-wrap: break-word;
          }
        }
      }
    }
  }
  .step {
    padding-bottom: 200px;
    .title {
      color: #333333;
      font-size: 36px;
      text-align: center;
      margin-bottom: 70px;
      @media screen and (max-width: 767px) {
        font-size: 24px;
      }
    }
    .strip {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      // border-radius: 16px 16px 16px 16px;
      margin: 0 auto;
      max-width: 952px;
      .item {
        width: 25%;
        height: 32px;
        text-align: center;
        background: #d6d6d6;
        &:first-child {
          border-radius: 16px 0 0 16px;
        }
        &:last-child {
          border-radius: 0 16px 16px 0;
        }
        .icon {
          margin-top: -5px;
          width: 40px;
        }
        .name {
          color: #666666;
          font-size: 24px;
          font-weight: bold;
          margin-top: 40px;
          margin-bottom: 20px;
          @media screen and (max-width: 767px) {
            font-size: 18px;
          }
        }
        .detail {
          color: #666666;
          font-size: 16px;
          line-height: 1.5;
          font-family: "Source Han Sans CN-Medium", serif;
          padding: 0 10px;
          @media screen and (max-width: 767px) {
            // display: none;
            font-size: 14px;
            white-space: normal;
            word-wrap: break-word;
          }
        }
      }
    }
  }
}
.process {
  background: #f5f5f5;
  padding: 80px 0;
}
.question {
  padding: 80px 0;
  .content {
    display: flex;
    justify-content: space-between;
    margin-top: 80px;
    .box {
      padding: 0 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;
      font-size: 20px;
      color: #333333;
      line-height: 2;
    }
    .left {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .top {
        width: 426px;
        height: 243px;
        box-sizing: border-box;

        background: url(../asset/development/n_dev_m_28.png) no-repeat
          center/contain;
      }
      .bottom {
        width: 426px;
        height: 243px;
        background: url(../asset/development/n_dev_m_29.png) no-repeat
          center/contain;
      }
    }
    .center {
      width: 560px;
      height: 560px;
      background: url(../asset/development/n_dev_m_5.png) no-repeat
        center/contain;
    }
    .right {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .top {
        width: 426px;
        height: 243px;
        background: url(../asset/development/n_dev_m_27.png) no-repeat
          center/contain;
      }
      .bottom {
        width: 426px;
        height: 243px;
        background: url(../asset/development/n_dev_m_30.png) no-repeat
          center/contain;
      }
    }
  }
}
.demand {
  padding: 80px 0 120px;
  background: rgba(221, 236, 250, 0.5);
  .list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 80px;
    .item {
      width: calc((100% - 180px) / 4);
      height: 418px;
      position: relative;
      cursor: pointer;
      &:hover {
        .content {
          top: 100%;
          opacity: 0;
          pointer-events: none;
        }
        .name {
          opacity: 1;
          top: 100%;
          bottom: auto;
        }
      }
      .content {
        position: absolute;
        transition: all 0.5s;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: rgba($color: #ffffff, $alpha: 0.5);
        backdrop-filter: blur(5px);
        text-align: center;
        padding-top: 80px;
        box-sizing: border-box;
        pre {
          margin: 0;
        }
        .icon {
          color: #3384e8;
        }
        .title {
          color: #3384e8;
          font-size: 24px;
          line-height: 2;
        }
        .brief {
          color: #666666;
          font-size: 14px;
          line-height: 2;
          margin-top: 30px;
        }
      }
      .name {
        font-size: 24px;
        color: #333333;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 24px 0;
        transition: all 0.5s;
        position: absolute;
        opacity: 0;
        left: 0;
        bottom: 0;
        width: 100%;
        line-height: 1.5;
        .icon {
          font-size: 36px;
          margin-right: 16px;
        }
      }
    }
  }
}
.genius {
  padding: 80px 0;
  .list {
    display: flex;
    flex-wrap: wrap;
    margin-top: 80px;

    .item {
      text-align: center;
      width: calc(25% - 123px);
      padding: 40px 0;
      margin-bottom: 80px;
      margin-right: 164px;
      box-shadow: 5px 5px 70px 1px #e8e8e8;
      &:nth-child(4n) {
        margin-right: 0;
      }
      .icon {
        width: 90px;
        margin-bottom: 30px;
      }
      .title {
        color: #333333;
        font-size: 24px;
      }
      .brief {
        color: #666666;
        font-size: 14px;
        line-height: 2;
      }
      &:nth-child(4) ~ .item {
        margin-bottom: 0;
      }
    }
  }
}
.ours {
  padding: 80px 0;
  background: url(../asset/development/n_dev_m_32.png) no-repeat center/cover;
  .list {
    display: flex;
    margin-top: 80px;
    .item {
      text-align: center;
      width: calc(25% - 96px);
      margin-right: 164px;
      background: url(../asset/development/n_dev_m_33.png) no-repeat
        center/contain;
      position: relative;
      font-size: 0;
      &:nth-child(4n) {
        margin-right: 0;
      }
      .back {
        width: 100%;
        position: relative;
        z-index: 2;
      }
      .content {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        z-index: 3;

        .icon {
          margin-top: 80px;
        }
        .name {
          font-size: 24px;
          color: #3384e8;
          margin-top: 15px;
        }
      }
      .extra {
        position: absolute;
        bottom: 0;
        right: 0;
        transform: translateX(40%);
        z-index: 1;
      }
      &::after {
        content: "01";
        position: absolute;
        right: 0;
        transform: translateX(50%);
        bottom: 80px;
        color: white;
        font-size: 24px;
        z-index: 999;
      }
      &:nth-child(2)::after {
        content: "02";
      }
      &:nth-child(3)::after {
        content: "03";
      }
      &:nth-child(4)::after {
        content: "04";
      }
    }
  }
}
.system {
  padding: 80px 0;
  .list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .item {
      background: no-repeat center/cover;
      width: 356px;
      height: 525px;
      margin: 0 48px;
      padding: 40px;
      box-sizing: border-box;
      box-shadow: 15px 5px 50px 1px #e6e6e6;
      margin-top: 80px;
      position: relative;
      &::after {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: rgba($color: #ffffff, $alpha: 0.4);
      }
      &:hover::after {
        opacity: 0;
      }
      .title {
        color: #333333;
        font-size: 24px;
        position: relative;
        padding-bottom: 16px;
        line-height: 1;
        &::after {
          content: "";
          position: absolute;
          width: 73px;
          height: 8px;
          background: #5078f4;
          border-radius: 7px 7px 7px 7px;
          left: 0;
          bottom: 0;
        }
      }
      .brief {
        line-height: 2;
        color: #999999;
        font-size: 16px;
      }

      &:nth-child(1) {
        background-image: url(../asset/development/n_dev_m_7.png);
      }
      &:nth-child(2) {
        background-image: url(../asset/development/n_dev_m_8.png);
      }
      &:nth-child(3) {
        background-image: url(../asset/development/n_dev_m_9.png);
      }
      &:nth-child(4) {
        background-image: url(../asset/development/n_dev_m_12.png);
      }
      &:nth-child(5) {
        background-image: url(../asset/development/n_dev_m_11.png);
      }
      &:nth-child(6) {
        background-image: url(../asset/development/n_dev_m_10.png);
      }
    }
  }
}
.quality {
  background: url(../asset//development/n_dev_m_13.png) no-repeat center/cover;
  padding: 80px 0;
  .content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .left {
      font-size: 36px;
      margin-right: 245px;
      color: white;
      flex-shrink: 0;
    }
    .right {
      display: flex;
      flex-wrap: wrap;
      gap: 100px 0;
      .item {
        display: flex;
        align-items: flex-start;
        width: 50%;
        .icon {
          width: 50px;
          margin-right: 32px;
        }
        .txt {
          color: #ffffff;
          .title {
            font-size: 24px;
          }
          .brief {
            font-size: 14px;
            line-height: 2;
          }
        }
      }
    }
  }
}
.inventory {
  padding: 80px 0;
  background: rgba(221, 236, 250, 0.5);
  .list {
    display: flex;
    flex-wrap: wrap;
    margin-top: 60px;

    .item {
      width: calc((100% - 96px) / 5);
      margin-bottom: 24px;
      background: white;
      box-shadow: 3px 5px 8px 1px rgba(170, 170, 170, 0.16);
      height: 140px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 24px;
      font-size: 24px;
      color: #333333;
      font-weight: 500;
      &:nth-child(5n) {
        margin-right: 0;
      }
      &:nth-child(5n) ~ .item {
        margin-bottom: 0;
      }
      .icon {
        margin-right: 16px;
      }
    }
  }
}
</style>
