<template>
  <div class="about">
    <top-nav :banner="globalData.about.imgList" class="top-nav">
    </top-nav>
    <div class="peculiarity" v-if="peculiarity">
      <strip-title :title="peculiarity.title1"></strip-title>
      <div class="main content-limit" style="margin-top: 80px">
        <div class="peculiarity-list">
          <div
            class="peculiarity-item"
            v-for="(item, i) in peculiarity.list"
            :key="i"
          >
            <div class="peculiarity-icon" v-html="item.svg"></div>
            <div class="peculiarity-title">{{ item.title }}</div>
            <div class="peculiarity-subtitle">{{ item.subtitle }}</div>
            <div class="peculiarity-description">{{ item.description }}</div>
          </div>
        </div>
        <strip-title
          :title="peculiarity.title2"
          style="margin-top: 80px"
        ></strip-title>
        <div class="dot-container">
          <div class="background">
            <img src="../asset/about/abg.png" alt="" />
          </div>
          <div class="describe">
            <div class="describe-content">
              <div class="describe-description">
                {{ peculiarity.description }}
              </div>
            </div>
            <div class="describe-shadow"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="honor" v-if="honor">
      <div class="main">
        <div class="common-title center">
          <div class="holder">
            <strip-title :title="honor.title"></strip-title>
            <div class="dot-container" style="margin-top: 80px">
              <div class="dot-content">
                <div class="circle-0"></div>
                <div class="circle-1"></div>
              </div>
            </div>
          </div>
          <div class="description">{{ honor.description }}</div>
        </div>
        <div class="img-container">
          <img src="../asset/about/left.png" @click="prev" alt="" />
          <div class="img-holder" ref="holder">
            <div class="img-item" v-for="(item, i) in honor.list" :key="i">
              <img :src="list[i]" alt="" />
              <div class="img-title">{{ item.title }}</div>
            </div>
          </div>
          <img src="../asset/about/right.png" @click="next" alt="" />
        </div>
      </div>
    </div>
    <!-- <aptitude :aptitude="globalData.about.aptitude"></aptitude> -->
    <my-footer></my-footer>
  </div>
</template>

<script>
import { WOW } from "wowjs";
import banner from "../asset/about/bg.png";
import { mapGetters } from "vuex";
import Aptitude from "./about/aptitude.vue";
import listic1 from "../asset/about/z-01.png";
import listic2 from "../asset/about/z-02.png";
import listic3 from "../asset/about/z-03.png";
import listic4 from "../asset/about/z-04.png";
import listic5 from "../asset/about/z-05.png";
import listic6 from "../asset/about/z-06.png";
import listic7 from "../asset/about/z-07.png";
import listic8 from "../asset/about/z-08.png";
import listic9 from "../asset/about/z-09.png";
import listic10 from "../asset/about/z-10.png";
import listic11 from "../asset/about/z-11.png";
import listic12 from "../asset/about/z-12.png";
import listic13 from "../asset/about/z-13.png";

export default {
  components: {
    Aptitude,
  },
  data() {
    return {
      banner,
      list: [
        listic1,
        listic2,
        listic3,
        listic4,
        listic5,
        listic6,
        listic7,
        listic8,
        listic9,
        listic10,
        listic11,
        listic12,
        listic13,
      ],
    };
  },
  methods: {
    prev() {
      const dom = this.$refs.holder;
      const to = dom.scrollLeft - dom.clientWidth;
      if (to < 0) {
        dom.scrollTo(0, 0);
      } else {
        dom.scrollTo(to, 0);
      }
    },
    next() {
      const dom = this.$refs.holder;
      const total = dom.scrollWidth;
      const to = dom.scrollLeft + dom.clientWidth;
      if (to > total) {
        dom.scrollTo(total, 0);
      } else {
        dom.scrollTo(to, 0);
      }
    },
  },
  computed: {
    ...mapGetters(["globalData"]),
    peculiarity() {
      return this.$store.getters.globalData.about.peculiarity;
    },
    honor() {
      return this.$store.getters.globalData.about.honor;
    },
  },
  mounted() {
    new WOW({
      live: false,
    }).init();
  },
};
</script>

<style scoped lang="scss">
$baseSize: 24;
@function px2rem($px) {
  @if calc($px / $baseSize * 0.75) <= 0.5 {
    @return 14px;
  }
  @return calc($px / $baseSize * 0.75rem);
}
@function px2rem_pure($px) {
  @return calc($px / $baseSize * 0.75rem);
}
.about {
  .banner-title {
    color: #3384e8;
    font-size: 48px;
    font-weight: bold;
    margin-bottom: 64px;
    text-align: left;
    text-align: center;
    @media screen and (max-width: 767px) {
      text-align: center;
      font-size: 24px;
    }
  }
  .banner-subtitle {
    color: white;
    font-size: 24px;
    margin-bottom: 64px;
    text-align: left;
    line-height: 2;
    font-family: "Source Han Sans CN-Medium", serif;
    @media screen and (max-width: 767px) {
      text-align: center;
      font-size: 18px;
      white-space: normal;
      word-wrap: break-word;
    }
  }
}

@media screen and (max-width: 1100px) {
  .about {
    .banner-text {
      position: absolute;
      width: 55%;
    }
  }
}
@media screen and (max-width: 768px) {
  .about {
    .banner-title {
      font-size: 24px;
      text-align: center;
    }
    .banner-subtitle {
      font-size: 18px;
      width: 100%;
    }
    .top-nav {
      padding-bottom: 300px;
    }
    .banner-text {
      position: absolute;
      width: 100%;
      padding: 20px;
      transform: translateY(30%);
    }
    .banner-mission {
      left: 50%;
      width: 80%;
      transform: translate(-50%, calc(100% + 120px));
      padding: 20px;
    }
  }
}

.peculiarity {
  padding: 80px 0;
  position: relative;
  &:before {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 251px;
    background: linear-gradient(180deg, #3197f1 0%, #3384e8 100%);
    bottom: 168px;
    left: 0;
  }
  .peculiarity-list {
    display: flex;
    justify-content: space-between;
    column-gap: calc(112 / 24) * 1rem;
    .peculiarity-item {
      flex: 1;
      border-radius: 8px;
      border: 1px solid rgba(51, 51, 51, 0.2);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;
      padding-top: 50px;
      padding-bottom: 50px;
      &:hover {
        background: linear-gradient(180deg, #3197f1 0%, #3384e8 100%);
        .peculiarity-icon,
        .peculiarity-title,
        .peculiarity-description {
          color: #ffffff;
          border-color: #ffffff;
          svg {
            color: #fff;
          }
        }
        .peculiarity-subtitle {
          color: rgba(255, 255, 255, 0.6);
        }
      }
      .peculiarity-icon {
        position: relative;
        width: calc(71 / 24) * 1rem;
        height: calc(71 / 24) * 1rem;
        transform: rotate(45deg);
        border: 1px solid rgba(51, 51, 51, 0.1);
        display: flex;
        justify-content: center;
        align-items: center;
        color: #3384e8;
        svg {
          display: block;
          width: 50%;
          padding: calc(32 / 24) * 1rem 0;
          transform: rotate(-45deg);
        }
      }
      .peculiarity-title {
        margin-top: calc(40 / 24) * 1rem;
        font-size: 24px;
        font-weight: 500;
        color: #333333;
      }
      .peculiarity-subtitle {
        margin-top: calc(9 / 24) * 1rem;
        font-size: 18px;
        font-weight: 400;
        color: #999999;
      }
      .peculiarity-description {
        margin-top: calc(40 / 24) * 1rem;
        font-size: 16px;
        font-weight: 400;
        color: #666666;
        line-height: 2;
        white-space: nowrap;
      }
    }
  }
  .dot-container {
    display: flex;
    position: relative;
    margin-top: 80px;
    padding: 0 50px;
    box-sizing: border-box;
    .background {
      width: 55.5%;
      aspect-ratio: 744 / 500;
      border-radius: 8px;
      position: relative;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        position: relative;
        z-index: 2;
      }
      .background-shadow {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #5078f4;
        border-radius: 8px 8px 8px 8px;
        transform: translate(40px, 40px);
        z-index: 1;
      }
    }
    .describe {
      position: absolute;
      width: 50%;
      right: 0;
      bottom: 0;
      box-sizing: border-box;
      .describe-content {
        background: #ffffff;
        padding: 100px 60px;
        border-radius: 8px;
        box-shadow: -3px 5px 8px 1px rgba(202, 202, 202, 0.16);
        z-index: 13;
        position: relative;
        transform: translateX(-10%);
      }
      .describe-title {
        font-size: 1rem;
        font-weight: bold;
        color: #333333;
      }
      .describe-subtitle {
        margin-top: calc(16 / 24) * 1rem;
        font-size: calc(18 / 24) * 1rem;
        font-weight: 400;
        color: #333333;
      }
      .describe-description {
        font-size: calc(16 / 24) * 1rem;
        font-weight: 400;
        color: #333333;
        line-height: 2;
      }
      .describe-shadow {
        background: #f4f4f4;
        box-shadow: -3px 5px 8px 1px rgba(202, 202, 202, 0.16);
        border-radius: 8px;
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        transform: translate(calc(-10% + 10px), 10px);
        z-index: 0;
        box-sizing: border-box;
      }
    }
  }
}

.honor {
  background: rgba(221, 236, 250, 0.5);
  padding: 80px 0;
  .img-container {
    display: flex;
    column-gap: 50px;
    & > img {
      object-fit: contain;
      cursor: pointer;
    }
    .img-holder {
      display: flex;
      flex: 1;
      overflow: hidden;
      column-gap: 80px;
      scroll-behavior: smooth;
    }
    .img-item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: calc(25% - 80px);
      min-width: 150px;
      gap: 20px;
      flex-shrink: 0;
      img {
        width: 100%;
        aspect-ratio: 241 / 336;
        object-fit: contain;
        max-width: 241px;
        justify-self: center;
        min-width: 120px;
      }
      .img-title {
        width: 100%;
        height: 40px;
        font-size: 16px;
        font-weight: 500;
        color: #333333;
        text-align: center;
        white-space: normal;
      }
    }
  }
}
</style>
