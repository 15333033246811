<template>
  <div class="partner">
    <strip-title
      :title="globalData.index.partner.title"
      :brief="globalData.index.partner.description"
    ></strip-title>
    <div class="list content-limit">
      <div class="item pi1" v-for="(item, index) of partner1" :key="index">
        <img :src="item" alt="" />
      </div>
    </div>
    <div class="list content-limit">
      <div class="item pi2" v-for="(item, index) of partner2" :key="index">
        <img :src="item" alt="" />
      </div>
    </div>
    <div class="list content-limit">
      <div class="item pi1" v-for="(item, index) of partner3" :key="index">
        <img class="img" :src="item" alt="" />
      </div>
    </div>
    <div class="button">
      查看更多<img class="icon" src="../../asset/index/index_60.png" alt="" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import anime from "animejs/lib/anime.es.js";
import partic1 from "../../asset/index/index_20.png";
import partic2 from "../../asset/index/index_71.png";
import partic3 from "../../asset/index/index_12.png";
import partic5 from "../../asset/index/index_13.png";
import partic6 from "../../asset/index/index_14.png";
import partic7 from "../../asset/index/index_15.png";
import partic8 from "../../asset/index/index_16.png";
import partic9 from "../../asset/index/index_17.png";
import partic10 from "../../asset/index/index_18.png";
import partic11 from "../../asset/index/index_10.png";
import partic12 from "../../asset/index/index_24.png";
import partic13 from "../../asset/index/index_23.png";
import partic14 from "../../asset/index/index_22.png";
import partic15 from "../../asset/index/index_21.png";

export default {
  data() {
    return {
      partner1: [partic1, partic2, partic3, partic5, partic6],
      partner2: [partic7, partic8, partic9, partic10, partic11],
      partner3: [partic12, partic13, partic14, partic15, partic1],
    };
  },
  computed: {
    ...mapGetters(["globalData"]),
  },
  mounted() {
    // this.$nextTick(() => {
    // new WOW({
    //   live: false,
    // }).init();
    // })
    //走马灯
    let xTrans = [];
    anime.set(".partner .pi1", {
      translateX: function (el, i, l) {
        xTrans[i] = { x: i * 282 };
        return i * 282;
      },
    });

    anime({
      targets: xTrans,
      duration: 35000, //走一周持续时间
      easing: "linear",
      x: "+=1128",
      loop: true,
      update: function (anim) {
        anime.set(".partner .pi1", {
          translateX: function (el, i, l) {
            return xTrans[i].x % 1410;
          },
        });
      },
    });
    // 走马灯2;
    let xTranss = [];
    anime.set(".partner .pi2", {
      translateX: function (el, i, l) {
        xTranss[i] = { xx: i * 282 };
        return i * 282;
      },
    });

    anime({
      targets: xTranss,
      duration: 35000, //走一周持续时间
      easing: "linear",
      xx: "+=1410",
      loop: true,
      update: function (anim) {
        anime.set(".partner .pi2", {
          translateX: function (el, i, l) {
            return xTranss[i].xx % 1410;
          },
        });
      },
    });
  },
};
</script>

<style scoped lang="scss">
.partner {
  padding: 80px 0;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    width: calc(((100% - 1440px) / 2) + 300px);
    height: 100%;
    left: 0;
    top: 0;
    background: linear-gradient(to right, white, rgba(255, 255, 255, 0.2));
    z-index: 9;
  }
  &::after {
    content: "";
    position: absolute;
    width: calc(((100% - 1440px) / 2) + 300px);
    height: 100%;
    right: 0;
    top: 0;
    background: linear-gradient(to left, white, rgba(255, 255, 255, 0.2));
    z-index: 9;
  }
  .list {
    position: relative;
    height: 190px;
    margin-top: 50px;
    overflow: hidden;
    .item {
      position: absolute;
      width: 282px;
      height: 190px;
      .img {
        object-fit: contain;
      }
    }
  }
  .button {
    color: white;
    font-size: 24px;
    width: 217px;
    height: 61px;
    margin: 0 auto;
    margin-top: 66px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background: linear-gradient(180deg, #3197f1 0%, #3384e8 100%);
    .icon {
      width: 30px;
    }
  }
}
</style>
