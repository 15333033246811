<template>
  <div>
    <div class="container">
      <div class="title">快速报价通道</div>
      <div>
        <input
          ref="nameInput"
          class="input-css"
          type="text"
          placeholder="请输入您的姓名"
        />
      </div>
      <div>
        <input
          ref="callInput"
          class="input-css"
          type="text"
          placeholder="请输入您的手机号"
        />
      </div>
      <div>
        <input
          ref="codeInput"
          class="input-css"
          type="text"
          placeholder="请输入验证码"
        />
      </div>
      <div class="code-img">
        <img
          class="hover-pop-check-icon"
          @click="getCode"
          ref="code"
          src=""
          alt=""
        />
      </div>
      <div class="submit-btn" @click="sendInfo">立即获取报价</div>
    </div>

    <div class="mask" v-if="showPop" @click="showPop = false">
      <div class="hover-pop-success">
        <img
          class="hover-pop-close"
          alt=""
          src="../../public/static-images/close.png"
          @click="showPop = false"
        />

        <img
          class="hover-pop-success-img"
          src="../../public/static-images/success.png"
          alt=""
        />
        <div class="hover-pop-success-title">提交成功</div>
        <div class="hover-pop-success-subtitle">
          请保持联系方式通畅，我们将会在1小时内与您联系！
        </div>
        <div class="hover-pop-success-info">
          <img class="hover-pop-success-phone" src="" alt="" />
          咨询电话:
          <div class="hover-pop-success-sp">400-849-4999</div>
        </div>
        <div class="hover-pop-success-info">
          (8:00-18:00)
          <div class="hover-pop-success-sp">133-5035-8828</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "quotation-channel",
  data() {
    return {
      imgDom: null,
      callDom: null,
      nameDom: null,
      codeInputDom: null,
      showPop: false,
    };
  },
  mounted() {
    this.imgDom = this.$refs.code;
    this.nameDom = this.$refs.nameInput;
    this.callDom = this.$refs.callInput;
    this.codeInputDom = this.$refs.codeInput;
    this.getCode();
  },
  methods: {
    getCode() {
      axios
        .get("https://api.dev.zhkcjt.com/support/other/captcha")
        .then((res) => {
          this.imgDom.src =
            "data:image/svg+xml;charset=utf-8," + encodeURIComponent(res.data);
        });
    },
    sendInfo() {
      axios
        .post(
          `https://api.dev.zhkcjt.com/support/user/websiteCommit/${this.codeInputDom.value}`,
          {
            source: GB_SOURCE || "未知",
            name: this.nameDom.value,
            mobile: this.callDom.value,
            remark: "",
            type: "soft",
          }
        )
        .then((res) => {
          if (res.data.status !== 1) {
            this.getCode();
            alert("验证码有误，请重新输入");
          } else {
            this.showPop = true;
            this.close();
            this.getCode();
          }
        });
    },
    close() {
      this.nameDom.value = "";
      this.callDom.value = "";
      this.codeInputDom.value = "";
    },
  },
};
</script>

<style scoped lang="scss">
.container {
  width: 100%;
  height: 85px;
  background-color: #353a5bd8;
  position: fixed;
  bottom: 0;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}
.title {
  color: #fff;
}
.code-img {
  background: white;
  height: 42px;
  margin: 0 20px;
}
.submit-btn {
  background: #2f74ff;
  color: #fff;
  font-size: 18px;
  padding: 12px;
  cursor: pointer;
}
.input-css {
  flex-grow: 1;
  background: #ffffff;
  border: 1px solid #cccccc;
  font-size: 16px;
  height: 40px;
  padding: 0 20px;
  outline: none;
  margin-left: 20px;
}
.mask {
  width: 100vw;
  height: 100vh;
  z-index: 999;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(10, 10, 10, 0.6);
}
.hover-pop-success {
  display: inherit;
  width: 657px;
  height: 480px;
  padding: 40px 0;
  text-align: center;
  box-sizing: border-box;
  border-radius: 16px;

  .hover-pop-success-img {
    width: 180px;
    margin: 0 auto;
  }
  .hover-pop-success-title {
    color: rgba(29, 218, 103, 1);
    font-size: 36px;
    margin-top: 20px;
  }
  .hover-pop-success-subtitle {
    color: rgba(102, 102, 102, 1);
    font-size: 16px;
    margin-top: 24px;
    margin-bottom: 25px;
  }
  .hover-pop-success-info {
    display: flex;
    align-items: center;
    color: rgba(102, 102, 102, 1);
    font-size: 18px;
    justify-content: center;
  }
  .hover-pop-success-phone {
    margin-right: 8px;
  }
  .hover-pop-success-sp {
    font-size: 24px;
    color: rgba(48, 151, 239, 1);
    margin-left: 16px;
  }
  .hover-pop-close {
    position: absolute;
    width: 20px;
    right: 24px;
    top: 24px;
    cursor: pointer;
  }
}
</style>
