<template>
  <div class="value">
    <div class="main">
      <strip-title :title="value.title"></strip-title>

      <div class="v-list">
        <div class="v-li" v-for="(item, i) in value.list" :key="i">
          <div class="inner">
            <div class="li-title">{{ item.title }}</div>
            <div class="li-value" v-for="(v, j) in item.description" :key="j">
              {{ v }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "value",
  props: ["value"],
};
</script>

<style scoped lang="scss">
$baseSize: 24;
@function px2rem($px) {
  @if calc($px / $baseSize * 0.75) <= 0.5 {
    @return 14px;
  }
  @return calc($px / $baseSize * 0.75rem);
}
@function px2rem_pure($px) {
  @return calc($px / $baseSize * 0.75rem);
}
.value {
  padding: 80px 0;

  .title-container {
    display: flex;
    flex-direction: column;
  }

  .v-list {
    display: grid;
    box-sizing: border-box;
    grid-template-columns: repeat(4, 1fr);
    margin-top: 80px;

    .v-li {
      width: 100%;
      display: flex;
      justify-content: center;
      padding: 0 px2rem_pure(50);
      padding-bottom: px2rem_pure(60);
      box-sizing: border-box;
      background: linear-gradient(180deg, #3197f1 0%, #3384e8 100%);

      &:nth-child(2n) {
        background: linear-gradient(180deg, #bfdeff 0%, #d2f2ff 100%);
        .li-title,
        .li-value {
          color: rgba(51, 132, 232, 1) !important;
          &::before {
            background: rgba(51, 132, 232, 1) !important;
          }
        }
      }

      .inner {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        padding-top: px2rem_pure(100);

        .li-title {
          white-space: pre;
          font-size: px2rem(24);
          font-weight: bold;
          color: #ffffff;
          margin-bottom: px2rem_pure(48);
          line-height: 1.5;
        }

        .li-value {
          width: 100%;
          font-size: px2rem(16);
          font-weight: 400;
          color: #ffffff;
          line-height: 2;
          text-align: left;
          display: grid;
          grid-template-columns: auto 1fr;
          column-gap: px2rem_pure(10);

          &:before {
            content: "";
            width: 8px;
            height: 8px;
            display: inline-block;
            background: white;
            border-radius: 50%;
            transform: translateY(8px);
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1100px) {
  .value .v-list {
    grid-template-columns: repeat(2, 1fr);
    .inner {
      width: 100%;
    }
    .v-li {
      &:nth-child(4n - 1) {
        background: #0d3bb2;
      }
      &:nth-child(4n) {
        background: #0f51fa;
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .value {
    .mt-title {
      font-size: 24px;
    }
  }
  .value .v-list {
    .v-li {
      padding: 15px;
      .inner {
        padding: 30px 0;
      }
    }
  }
}
@media screen and (max-width: 375px) {
  .value .v-list {
    grid-template-columns: repeat(1, 1fr);
  }
}
</style>
