<template>
  <div class="container">
    <top-nav :banner="globalData.development.imgList"></top-nav>
    <div class="range content-limit">
      <strip-title
        :title="globalData.development.range.topic.title"
        :brief="globalData.development.range.topic.brief"
      ></strip-title>
      <div class="list">
        <div
          class="box"
          v-for="(item, index) of globalData.development.range.list"
          :key="index"
        >
          <div class="fancy">
            <div class="item">
              <img class="icon" :src="range[index]" alt="" />
            </div>
            <div class="name">{{ item }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="management">
      <strip-title
        :title="globalData.development.management.topic.title"
      ></strip-title>
      <div class="list list1 content-limit">
        <div
          class="item"
          v-for="(item, index) of globalData.development.management.list[0]"
          :key="index"
        >
          <div class="img">
            <img class="icon" :src="management[0][index]" alt="" />
          </div>
          <div class="name">{{ item }}</div>
        </div>
      </div>
      <div class="list list2 content-limit">
        <div
          class="item"
          v-for="(item, index) of globalData.development.management.list[1]"
          :key="index"
        >
          <div class="img">
            <img class="icon" :src="management[1][index]" alt="" />
          </div>
          <div class="name">{{ item }}</div>
        </div>
      </div>
    </div>
    <div class="tailor">
      <strip-title
        :title="globalData.development.merits.title"
        :brief="globalData.development.merits.description"
      ></strip-title>
      <div class="list content-limit">
        <div
          class="item"
          v-for="(item, index) of globalData.development.merits.list"
          :key="index"
        >
          <img class="img" :src="tailor[index]" alt="" />
          <pre class="name">{{ item }}</pre>
        </div>
      </div>
    </div>
    <div class="why">
      <strip-title
        :title="globalData.development.why.topic.title"
        :brief="globalData.development.why.topic.brief"
      ></strip-title>
      <div class="list content-limit">
        <div
          class="item"
          v-for="(item, index) of globalData.development.why.list"
          :key="index"
        >
          <div class="title">
            <img class="icon" :src="why[index]" alt="" />
            {{ item.title }}
          </div>
          <pre class="brief">{{ item.brief }}</pre>
        </div>
      </div>
    </div>
    <div class="process content-limit">
      <strip-title
        :title="globalData.index.process.topic.title"
        :brief="globalData.index.process.topic.brief"
      ></strip-title>
      <serve-process></serve-process>
    </div>
    <div class="inventory">
      <strip-title
        :title="globalData.development.inventory.topic.title"
        :brief="globalData.development.inventory.topic.brief"
      ></strip-title>
      <div class="list content-limit">
        <div
          class="item"
          v-for="(item, index) of globalData.development.inventory.list"
          :key="index"
        >
          <img class="icon" :src="inventory[index]" alt="" />
          <pre class="name">{{ item }}</pre>
        </div>
      </div>
    </div>
    <my-footer></my-footer>
  </div>
</template>
<script>
import { WOW } from "wowjs";
import banner from "../asset/development/n_dev_12.png";
import { mapGetters } from "vuex";
import DeliveryChecklist from "./development/DeliveryChecklist.vue";
import Advantage from "./development/Advantage.vue";
import Merits from "./development/Merits.vue";
import rangeic1 from "../asset/development/n_dev_11.png";
import rangeic2 from "../asset/development/n_dev_14.png";
import rangeic3 from "../asset/development/n_dev_15.png";
import rangeic4 from "../asset/development/n_dev_13.png";
import rangeic5 from "../asset/development/n_dev_16.png";
import manageic1 from "../asset/development/dev_09.png";
import manageic2 from "../asset/development/dev_10.png";
import manageic3 from "../asset/development/dev_21.png";
import manageic4 from "../asset/development/dev_11.png";
import manageic5 from "../asset/development/dev_12.png";
import manageic6 from "../asset/development/dev_13.png";
import tailoric1 from "../asset/development/n_dev_23.png";
import tailoric2 from "../asset/development/n_dev_27.png";
import tailoric3 from "../asset/development/n_dev_28.png";
import tailoric4 from "../asset/development/n_dev_29.png";
import tailoric5 from "../asset/development/n_dev_32.png";
import tailoric6 from "../asset/development/n_dev_2.png";
import tailoric7 from "../asset/development/n_dev_31.png";
import tailoric8 from "../asset/development/n_dev_30.png";
import whyic1 from "../asset/development/n_dev_3.png";
import whyic2 from "../asset/development/n_dev_18.png";
import whyic3 from "../asset/development/n_dev_19.png";
import whyic4 from "../asset/development/n_dev_4.png";
import whyic5 from "../asset/development/n_dev_5.png";
import whyic6 from "../asset/development/n_dev_20.png";
import invic1 from "../asset/development/n_dev_21.png";
import invic2 from "../asset/development/n_dev_33.png";
import invic3 from "../asset/development/n_dev_6.png";
import invic4 from "../asset/development/n_dev_22.png";
import invic5 from "../asset/development/n_dev_7.png";
import invic6 from "../asset/development/n_dev_24.png";
import invic7 from "../asset/development/n_dev_25.png";
import invic8 from "../asset/development/n_dev_26.png";
import invic9 from "../asset/development/n_dev_8.png";
import invic10 from "../asset/development/n_dev_9.png";

export default {
  components: {
    DeliveryChecklist,
    Advantage,
    Merits,
  },
  data: function () {
    return {
      banner,
      range: [rangeic1, rangeic2, rangeic3, rangeic4, rangeic5],
      tailor: [
        tailoric1,
        tailoric2,
        tailoric3,
        tailoric4,
        tailoric5,
        tailoric6,
        tailoric7,
        tailoric8,
      ],
      management: [
        [manageic1, manageic2, manageic3],
        [manageic4, manageic5, manageic6],
      ],
      why: [whyic1, whyic2, whyic3, whyic4, whyic5, whyic6],
      inventory: [
        invic1,
        invic2,
        invic3,
        invic4,
        invic5,
        invic6,
        invic7,
        invic8,
        invic9,
        invic10,
      ],
    };
  },
  computed: {
    ...mapGetters(["globalData"]),
  },
  mounted() {
    // this.$store.commit('setBannerChildIndex', 0)
    // this.$nextTick(() => {
    new WOW({
      live: false,
    }).init();
    // })
  },
};
</script>
<style scoped lang="scss">
.banner-title {
  color: #3384e8;
  font-size: 48px;
  font-weight: bold;
  margin-bottom: 64px;
  @media screen and (max-width: 767px) {
    text-align: center;
    font-size: 24px;
  }
}
.banner-subtitle {
  color: white;
  font-size: 24px;
  text-align: left;
  line-height: 2;
  font-family: "Source Han Sans CN-Medium", serif;
  @media screen and (max-width: 767px) {
    white-space: normal;
    word-wrap: break-word;
    text-align: center;
    font-size: 18px;
  }
}
.range {
  padding: 80px 0;
  .list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    .box {
      flex: 0 1 33%;
      box-sizing: border-box;
      margin: 54px 0 0;
      padding: 0 27px;
      .fancy {
        box-shadow: 3px 5px 20px 1px rgba(125, 125, 125, 0.16);
      }
      .item {
        display: flex;
        justify-content: center;
        align-items: center;
        .icon {
          width: 100%;
        }
      }
      .name {
        font-size: 24px;
        color: #3384e8;
        height: 77px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      @media screen and (max-width: 1200px) {
        flex-basis: 33%;
      }
      @media screen and (max-width: 900px) {
        flex-basis: 50%;
      }
    }
  }
}
.management {
  padding: 70px 0 0;
  // background: #f5f5f5;
  background: url("../asset/development/n_dev_10.png") no-repeat center/cover;

  .list {
    display: flex;
    flex-wrap: wrap;
    .item {
      margin-bottom: 60px;
      .img {
        display: flex;
        justify-content: center;
        align-items: center;
        background: linear-gradient(180deg, #3197f1 0%, #3384e8 100%);
        width: 258px;
        height: 166px;

        .icon {
          width: 80px;
        }
      }
      .name {
        font-size: 24px;
        color: #333333;
        margin-top: 33px;
        text-align: center;
        font-weight: bold;
      }
    }
    @media screen and (max-width: 1000px) {
      justify-content: center !important;
    }
  }
  .list1 {
    margin-top: 80px;
    justify-content: flex-start;
    .item {
      margin-right: 47px;
      @media screen and (max-width: 532px) {
        margin-right: 0;
      }
    }
  }
  .list2 {
    justify-content: flex-end;
    .item {
      margin-left: 47px;
      @media screen and (max-width: 532px) {
        margin-left: 0;
      }
    }
  }
}
.process {
  background: #ffffff;
  padding: 80px 0;
}
.tailor {
  padding: 80px 0;
  .list {
    display: flex;
    flex-wrap: wrap;
    margin-top: 60px;
    .item {
      width: calc((100% - 384px) / 4);
      margin-right: 128px;
      margin-bottom: 40px;
      transform: translateX(40px);
      &:nth-child(4n) {
        margin-right: 0;
      }
      &:nth-child(4) ~ .item {
        transform: translateX(0);
      }
      .img {
      }
      .name {
        color: #333333;
        font-size: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 2;
        text-align: center;
        margin: 0;
      }
    }
  }
}
.why {
  padding: 80px 0;
  background: url(../asset/development/n_dev_17.png) no-repeat center/cover;
  .list {
    display: flex;
    flex-wrap: wrap;
    margin-top: 60px;
    justify-content: center;
    .item {
      background: white;
      width: 330px;
      margin-right: 80px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      widows: 330px;
      height: 182px;
      &:nth-child(3n) {
        margin-right: 0;
      }
      &:nth-child(3) ~ .item {
        margin-top: 80px;
      }
      .title {
        display: flex;
        align-items: center;
        color: #3384e8;
        font-size: 24px;
        .icon {
          width: 24px;
          margin-right: 8px;
        }
      }
      .brief {
        color: #333333;
        font-size: 14px;
        line-height: 2;
        margin-top: 15px;
        text-align: center;
      }
    }
  }
}
.inventory {
  padding: 80px 0;
  background: rgba(221, 236, 250, 0.5);
  .list {
    display: flex;
    flex-wrap: wrap;
    margin-top: 60px;

    .item {
      width: calc((100% - 96px) / 5);
      margin-bottom: 24px;
      background: white;
      box-shadow: 3px 5px 8px 1px rgba(170, 170, 170, 0.16);
      height: 140px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 24px;
      font-size: 24px;
      color: #333333;
      font-weight: 500;
      &:nth-child(5n) {
        margin-right: 0;
      }
      &:nth-child(5n) ~ .item {
        margin-bottom: 0;
      }
      .icon {
        margin-right: 16px;
      }
    }
  }
}
</style>
