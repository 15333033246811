<template>
	<div class="ability">
		<div class="card-bg"></div>
		<div class="main">
			<div class="title-container">
				<div class="ab-title">
					<div class="title-value">{{ ability.title }}</div>
				</div>
				<div class="ab-subtitle">{{ ability.subtitle }}</div>
			</div>
			<div class="card-list">
				<div class="card-li" v-for="(item, i) in ability.list" :key="i">
					<div class="card-head">
						<div class="cbc-head">
							<img :src="getImg(item.icon)" alt="">
							{{ item.title }}
						</div>
						<div class="cbc-description">{{ item.subtitle.replace(/\n|\r\n/g, '、') }}</div>
					</div>
					<div class="card-body">
						<img class="cb-bg" :src="getImg(item.background)" alt="">
						<div class="cb-content">
							<div class="cbc-head">
								<img :src="getImg(item.icon)" alt="">
								{{ item.title }}
							</div>
							<div class="cbc-description">{{ item.subtitle }}</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "ability",
	props: ['ability'],
	methods: {
		getImg (file) {
			return `${ window.origin }${ process.env.BASE_URL || '/' }${ file }`
		},
	},
}
</script>

<style scoped lang="scss">
$baseSize: 24;
@function px2rem($px) {
	@if calc($px / $baseSize * 0.75) <= 0.5 {
		@return 14px;
	}
	@return calc($px / $baseSize * 0.75rem);
}
@function px2rem_pure($px) {
	@return calc($px / $baseSize * 0.75rem);
}
.ability {
	padding-top: px2rem(100);
	background: white;
	padding-bottom: px2rem(50);
	position: relative;
	.title-container {
		display: flex;
		flex-direction: column;
	}
	.ab-title {
		align-self: flex-start;
		font-size: px2rem(48);
		font-weight: bold;
		color: #333333;
		.title-value {
			&::after {
				display: block;
				content: '';
				width: 100%;
				height: 15px;
				background: #0F52FB;
				margin-top: 6px;
			}
		}
	}
	.ab-subtitle {
		align-self: flex-start;
		font-size: px2rem(18);
		font-weight: 400;
		color: #999999;
		display: flex;
		justify-content: flex-start;
		padding: px2rem(26) 0 px2rem(50) 0;
	}
	.card-bg {
		position: absolute;
		width: 100%;
		height: px2rem(318);
		bottom: px2rem(100);
		background: #1A1A1A;
	}
	.card-list {
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		grid-auto-rows: px2rem(818);
		justify-content: space-between;
		position: relative;

		.card-li {
			height: 100%;
			display: flex;
			flex-direction: column;
			border: 4px solid transparent;
			box-sizing: border-box;
			transition: all .4s;
			z-index: 1;
			&:hover {
				border-color: #0f51fa;
				.card-head {
					opacity: 1;
					.cbc-head {
						transform: translateY(0);
					}
					.cbc-description {
						transform: translateY(0);
					}
				}
				.card-body {
					.cb-bg{
						filter: grayscale(0) brightness(1);
					}
				}
				.cb-content {
					.cbc-head, .cbc-description {
						transform: translateY(-418px);
					}
				}
			}
		}
		.card-head {
			flex: 1;
			opacity: 0;
			background: #0f51fa;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			row-gap: px2rem(24);
			position: relative;
			overflow: hidden;
			padding: 0 px2rem_pure(10);
			transition: all .4s;
			.cbc-head {
				display: flex;
				align-items: center;
				justify-content: center;
				column-gap: px2rem_pure(16);
				font-size: px2rem(36);
				font-weight: 500;
				color: #FFFFFF;
				transition: all .4s;
				transform: translateY(300px);
				white-space: nowrap;
				img {
					width: px2rem(36);
					height: px2rem(36);
				}
			}
			.cbc-description {
				font-size: px2rem(14);
				font-weight: 400;
				color: #FFFFFF;
				line-height: 1.75;
				white-space: pre-wrap;
				transition: all .4s;
				transform: translateY(300px);
			}
		}
		.card-body {
			position: relative;
			flex-shrink: 0;
			height: px2rem(557);
			.cb-bg {
				width: 100%;
				height: 100%;
				object-fit: cover;
				filter: grayscale(1) brightness(0.5);
				transition: all .6s;
				position: absolute;
			}
			.cb-content {
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				row-gap: px2rem(24);
				position: relative;
				overflow: hidden;
				height: 100%;
				.cbc-head {
					display: flex;
					align-items: center;
					justify-content: center;
					column-gap: px2rem(16);
					font-size: px2rem(36);
					font-weight: 500;
					color: #FFFFFF;
					transition: all .4s;
					img {
						width: px2rem(36);
					}
				}
				.cbc-description {
					font-size: px2rem(14);
					font-weight: 400;
					color: #FFFFFF;
					line-height: 1.75;
					white-space: pre;
					transition: all .4s;
				}
			}
		}
	}
}
@media screen and (max-width: 1280px) {
	.ability {
		.card-list {
			grid-template-columns: repeat(2, 1fr);
			grid-column-gap: px2rem(40);
			grid-row-gap: px2rem(80);
			position: relative;
			grid-auto-rows: 660px;
			&:before {
				display: block;
				width: calc(100% + 80px);
				height: 318px;
				content: '';
				background: #1A1A1A;
				position: absolute;
				top: 280px;
				left: -40px;
			}
			.card-body {
				height: 525px;
			}
			.card-li:hover {
				border-color: #0f51fa;
				.card-head {
					opacity: 1;
					.cbc-head {
						transform: translateY(0);
					}
					.cbc-description {
						transform: translateY(0);
					}
				}
				.card-body {
					.cb-bg{
						filter: grayscale(0) brightness(1);
					}
				}
				.cb-content {
					.cbc-head, .cbc-description {
						transform: translateY(-525px);
					}
				}
			}
		}
	}
}
@media screen and (max-width: 768px){
	.ability {
		.ab-title {
			font-size: 24px;
		}
		.card-list {
			grid-auto-rows: unset;
			grid-column-gap: 10px;
			grid-row-gap: 20px;
			grid-template-columns: repeat(1, 1fr);
			&:before {
				display: none;
			}
			.card-body {
				aspect-ratio: 361 / 418;
				min-width: 150px;
				height: 100%;
			}
			.card-head {
				display: none;
			}
			.card-li:hover {
				border-color: #0f51fa;
				.card-head {
					opacity: 1;
					.cbc-head {
						transform: translateY(0);
					}
				}
				.card-body {
					.cb-bg{
						filter: grayscale(0) brightness(1);
					}
				}
				.cb-content {
					background: rgba(0,0,0,0.4);
					.cbc-head, .cbc-description {
						transform: translateY(0);
					}
				}
			}
		}
		.card-bg {
			position: absolute;
			width: 100%;
			height: 100px;
			bottom: px2rem(100);
			background: #1A1A1A;
		}
	}
}
</style>
