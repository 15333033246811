<template>
  <div class="development-type">
    <div class="main">
      <strip-title :title="type.title" :brief="type.brief"></strip-title>

      <div class="dt-list">
        <div class="dt-li" v-for="(item, i) in type.list" :key="i">
          <div class="li-card">
            <div class="card-img-content">{{ item.description }}</div>
            <div class="card-description" :title="item.description">
              <img :src="getImg(item.icon)" @load="whenLoaded" alt="图标" />
              <span>{{ item.title }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as SvgInject from "@iconfu/svg-inject";

export default {
  name: "common-solution",
  data: function () {
    return {
      SvgInject,
    };
  },
  props: ["type"],
  methods: {
    getImg(file) {
      return `${window.origin}${process.env.BASE_URL || "/"}${file}`;
    },
    whenLoaded(e) {
      const dom = e.target;
      SvgInject(dom);
    },
  },
};
</script>

<style scoped lang="scss">
$baseSize: 24;
@function px2rem($px) {
  @if calc($px / $baseSize * 0.75) <= 0.5 {
    @return 14px;
  }
  @return calc($px / $baseSize * 0.75rem);
}
.development-type {
  padding: px2rem(100) 0 px2rem(64) 0;
  background: #f5f5f5;
  box-sizing: border-box;
  .main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .dt-list {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: px2rem(80);
    justify-content: space-between;
    margin-top: px2rem(50);
    box-sizing: border-box;
    .dt-li {
      display: flex;
      flex-direction: column;
      aspect-ratio: 238 / 340;
    }
    .li-card {
      background: white;
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      flex: 1;
      border-radius: 8px;
      box-shadow: 5px 5px 70px 1px #e8e8e8;
      transition: all 0.2s;
      &:hover {
        background: linear-gradient(180deg, #3197f1 0%, #3384e8 100%);
        .card-img-content {
          color: white;
        }
        .card-description {
          color: rgba(51, 132, 232, 1);
          background: white;
          span {
            color: rgba(51, 132, 232, 1) !important;
          }
        }
      }
      .card-img-content {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 240px;
        white-space: pre;
        font-size: px2rem(16);
        font-weight: 400;
        color: #666666;
        line-height: 2;
      }
      .card-description {
        border-radius: 8px 8px 8px 8px;
        background: linear-gradient(180deg, #3197f1 0%, #3384e8 100%);
        flex-shrink: 0;
        color: #ffffff;
        line-height: 2;
        padding: px2rem(24);
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
        column-gap: px2rem(16);

        svg {
          width: 30px;
          height: 24px;
        }

        span {
          font-size: px2rem(24);
          font-weight: bold;
          color: #ffffff;
        }
      }
    }
    .li-title {
      flex-shrink: 0;
      font-size: px2rem(24);
      font-weight: bold;
      color: #333333;
      text-align: center;
      margin-top: px2rem(24);
    }
  }
}
@media screen and (max-width: 1280px) and (min-width: 768px) {
  .development-type {
    .dt-list {
      grid-template-columns: repeat(4, 1fr);
      column-gap: px2rem(20);
      .dt-li:last-child {
        max-height: 400px;
        justify-self: center;
        grid-column: 3 / 5;
      }
      .dt-li:nth-child(9) {
        justify-self: center;
        max-height: 400px;
        grid-column: 1 / 3;
      }

      .li-card {
        .card-img-content {
          //min-height: 400px;
        }
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .development-type {
    padding: 50px 0 32px 0;
    .dt-title {
      font-size: 24px;
    }
    .dt-list {
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 10px;
      grid-row-gap: 20px;
      .li-card {
        max-height: 280px;
        .card-img-content {
          min-height: unset;
        }
        .card-description {
          padding: 10px;
          svg {
            width: 30px;
          }
        }
      }
    }
  }
}
</style>
