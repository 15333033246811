// import flexAble from './utils/lib-flexable.js';
import './asset/common/common.css'
import 'normalize.css';
import 'animate.css';
import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import router from './router/index.js';
import store from './store/index.js';
import App from './App.vue';
import topNav from './components/top-nav.vue';
import stripTitle from './components/strip-title.vue';
import myPartner from './components/my-partner.vue';
import myFooter from './components/my-footer.vue';
import ScrollToTop from "./components/scroll-to-top.vue";
import quotationChannel from "./components/quotation-channel.vue";
import serveProcess from "./components/serve-process.vue";

// flexAble(window, window['lib'] || (window['lib'] = {}))
Vue.use(VueAxios, axios);
const bus = new Vue();

Vue.prototype.$bus = bus;
Vue.prototype.$axios = axios;

Vue.component('top-nav', topNav);
Vue.component('strip-title', stripTitle);
Vue.component('my-partner', myPartner);
Vue.component('my-footer', myFooter);
Vue.component('scroll-to-top', ScrollToTop);
Vue.component('quotation-channel', quotationChannel);
Vue.component('serve-process', serveProcess);

console.log(process.env.BASE_URL);

new Vue({
	router,
	store,
	render: (h) => h(App),
	mounted() {
		document.dispatchEvent(new Event('render-event'))
	}
}).$mount('#root');
