var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"nav"},[_c('div',{staticClass:"banner-list",on:{"mouseenter":_vm.stopCount,"mouseleave":_vm.startCount}},_vm._l((_vm.banner),function(item,index){return _c('div',{key:index,staticClass:"banner-item",style:({
          transform: `translateX(${(index - _vm.currentIndex) * 100}%)`,
          background: `url(${require(`../asset/${
            item.img || 'index/index_25.png'
          }`)}) no-repeat center/cover`,
        })},[(_vm.isHome && index === 0)?_c('div',{staticClass:"text-container"},[_c('div',{staticClass:"top-text"},[_vm._v(_vm._s(item.topText))]),_vm._v(" "),_c('div',{staticClass:"title"},[_vm._v(_vm._s(item.title))]),_vm._v(" "),_c('div',{staticClass:"line"}),_vm._v(" "),_c('div',{staticClass:"desc",domProps:{"innerHTML":_vm._s(item.desc)}})]):_c('div',{staticClass:"text-container2"},[_c('div',{staticClass:"top-text"},[_vm._v("\n            "+_vm._s(item.topText)+"\n          ")]),_vm._v(" "),_c('div',{staticClass:"title"},[_vm._v(_vm._s(item.title))]),_vm._v(" "),_c('div',{staticClass:"line"}),_vm._v(" "),_c('div',{staticClass:"desc",style:({ fontSize: item.descFont ?? '24px' }),domProps:{"innerHTML":_vm._s(item.desc)}}),_vm._v(" "),_c('div',{staticClass:"button",on:{"click":function($event){return _vm.handleButton(item.buttonText)}}},[_vm._v("\n            "+_vm._s(item.buttonText)+"\n          ")])])])}),0),_vm._v(" "),_c('div',{staticClass:"content"},[_c('div',{staticClass:"content-limit"},[_c('img',{staticClass:"logo",attrs:{"src":require("../asset/index/index_26.png"),"alt":""}}),_vm._v(" "),_vm._m(0),_vm._v(" "),_c('div',{staticClass:"tabs"},[_c('div',{staticClass:"query"},[_c('div',{staticClass:"button",style:({
                background: `url(${_vm.navicon2}) no-repeat center/30px`,
              }),on:{"mousedown":function($event){$event.stopPropagation();return _vm.switchNav.apply(null, arguments)}}}),_vm._v(" "),(_vm.showNav)?_c('div',{staticClass:"list"},_vm._l((_vm.globalData.common.navs),function(item,index){return _c('div',{key:index,staticClass:"item",class:{ active: _vm.bannerIndex == index }},[_c('div',{ref:"tabs",refInFor:true,staticClass:"inner",on:{"click":function($event){return _vm.nav(index, item.name)}}},[_vm._v("\n                  "+_vm._s(item.name)+"\n                ")]),_vm._v(" "),(typeof item.route !== 'string')?_c('div',{staticClass:"child"},[_c('div',{staticClass:"child-inner"},_vm._l((item.route),function(innerItem,innerIndex){return _c('div',{key:innerIndex,staticClass:"child-item",class:{
                        'child-active': _vm.bannerChildIndex == innerIndex,
                      },on:{"mousedown":function($event){return _vm.childNav(index, innerIndex)}}},[_c('img',{staticClass:"nav-icon",attrs:{"src":require(`../asset/nav/${
                            innerItem.icon || 'software-dev-icon3'
                          }.png`),"alt":""}}),_vm._v("\n                      "+_vm._s(innerItem.name)+"\n                    ")])}),0)]):_vm._e()])}),0):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"phone-content"},[_c('div',{staticClass:"phone",on:{"click":function($event){return _vm.call('400-849-4999')}}},[_c('img',{staticClass:"phone-icon",attrs:{"src":require("../asset/index/index_63.png"),"alt":""}}),_vm._v("\n              全国服务热线:\n              "),_c('div',{staticClass:"phone-num"},[_vm._v("400-849-4999")])])])])])]),_vm._v(" "),_c('div',{staticClass:"hover content-limit"},[_c('div',{staticClass:"digital"},[_vm._t("digital")],2)]),_vm._v(" "),(_vm.isHome)?_c('div',{staticClass:"indicator"},_vm._l((_vm.banner),function(item,index){return _c('div',{key:index,staticClass:"indicator-item",class:{ 'indicator-item-active': _vm.currentIndex === index },on:{"click":function($event){return _vm.setCurrentIndex(index)}}})}),0):_vm._e()])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"slogan"},[_vm._v("国家高新技术企业"),_c('br'),_vm._v("国家3A资质认证")])
}]

export { render, staticRenderFns }